import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forgot } from '../../services/password.service';
import { AuthTemplate, Form, Field, Row, PageBox, Button } from '../../components';
import SuccessContent from '../../components/SuccessContent/SuccessContent';
import { useStoreActions } from 'easy-peasy';

export default () => {
  const { t } = useTranslation();
  const [successResponseStatus, setSuccessResponseStatus] = useState(false);
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);

  const handleSubmit = setError => data => {
    addLoader();
    forgot(data)
      .then(() => {
        removeLoader();
        setSuccessResponseStatus(true);
      })
      .catch(error => {
        removeLoader();
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
        console.log('[ERROR] forgot password: ', error);
      })
  }

  return (
    <PageBox>
      <AuthTemplate
        heading={t('forgotPasswordHeading')}
        subheading={t('forgotPasswordDescription')}
      >
        { successResponseStatus && <SuccessContent title={t('successForgotPassword')} close={() => setSuccessResponseStatus(false)} /> }
        <Form
          onSubmit={handleSubmit}
        >
          {({ register, errors }) => (
            <>
              <Row>
                <Field
                  error={errors.email}
                >
                  <input
                    id='email'
                    name='email'
                    ref={register}
                    placeholder={t('emailAddress')}
                  />
                </Field>
              </Row>
              <Row center>
                <Button type='submit' text={t('resetPasswordButtonText')} md primary />
              </Row>
            </>
          )}
        </Form>
      </AuthTemplate>
    </PageBox>
  );
};
