import React from 'react';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/images/logo.svg';

import { LoaderWrapper, LoaderBox, LoaderCircle, LoaderLogo } from './Loader.styled';

const Loader = ({ visible }) => {
  if (!visible) return null;
  return (
    <LoaderWrapper>
      <LoaderBox>
        <LoaderCircle />
        <LoaderLogo>
          <ReactSVG src={logo} />
        </LoaderLogo>
      </LoaderBox>
    </LoaderWrapper>
  );
};

export default Loader;
