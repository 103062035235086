export default [
  { value: 'PL', label: 'PL' },
  { value: 'EN', label: 'EN' },
  { value: 'ES', label: 'ES' },
  { value: 'SK', label: 'SK' },
  { value: 'CZ', label: 'CZ' },
  { value: 'HU', label: 'HU' },
  { value: 'RO', label: 'RO' },
  { value: 'FR', label: 'FR' },
  { value: 'NL', label: 'NL' },
  { value: 'DK', label: 'DK' },
  { value: 'IT', label: 'IT' },
  { value: 'IE', label: 'IE' },
  { value: 'IL', label: 'IL' },
  { value: 'BE', label: 'BE' },
];
