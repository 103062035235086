import styled, { css } from 'styled-components';

export const SliderWrapper = styled.div`
  .slick-slider {
    display: flex;
    align-items: center;
  }

  .slick-list {
    max-width: 1045px;
    height: 220px;
    margin: 0 auto;
  }

  .slick-slide {
    width: 185px;
    height: 220px;

    margin: 0 12px;
  }
`;

export const ProductsSlider = styled.div`
  margin: 0 -12px;
`;

export const Arrow = styled.div`
  display: flex;

  width: 44px;
  
  ${({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `}
  
  svg {
    width: 18px;
    height: 30px;
  }
`;

export const ArrowContainer = styled.div`
  width: 44;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;

export const ArrowIconWrapper = styled.div`
  width: 18px;
  height: 30px;

  cursor: pointer;
`;