import styled, { css } from 'styled-components';

export const Button = styled.button`
  min-height: 60px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.25}px;
    font-weight: ${theme.fontWeight.black};
    /* line-height: ${theme.fontSize.base * 2}px; */

    background-color: black;
    color: white;

    padding: 0 ${theme.gutter}px;
  `}

  ${({ primary, theme }) =>
  primary &&
  theme &&
  css`
    background-color: ${theme.colors.primary};
    color: white;

    &:hover {
      background-color: ${theme.colors.primaryHover};
    }
  `}

  ${({ sm, theme }) =>
  sm &&
  theme &&
  css`
    font-size: ${theme.fontSize.base * .875}px;
    min-height: ${theme.gutter * .8}px;
  `}

  ${({ md, theme }) =>
  md &&
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.125}px;

    min-height: ${theme.gutter * 1.5}px;
  `}

  ${({ wider, theme }) =>
  wider &&
  css`
    padding: 0 ${theme.gutter * 2.5}px;
  `}
`;