import styled, { css } from 'styled-components';
import { Collapse } from 'react-collapse';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray300};
`;
export const Arrow = styled.svg`
  height: 13px;
  flex-shrink: 0;

  margin-left: auto;

  fill: ${({ theme }) => theme.colors.primary};

  transform: rotate(90deg);

  ${({ active }) =>
  active &&
  css`
    transform: rotate(-90deg);
  `}
`;
export const Header = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  ${({ theme }) =>
  theme &&
  css`
    min-height: ${theme.gutter * 1.5}px;
    padding: 0 ${theme.gutter / 2}px;
    
    font-weight: ${theme.fontWeight.black};
  `}
`;
export const Heading = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.25}px;
    margin-right: ${theme.gutter * .75}px;
  `}
`;
export const Content = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    padding: 0 ${theme.gutter / 2}px ${theme.gutter / 1.5}px;
  `}
`;

export const SerialNumber = styled.p`
  color: #989898;
  ${({ theme }) =>
  theme &&
  css`
    padding: 0 ${theme.gutter / 3}px;
  `}
`;