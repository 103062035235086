import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PrimaryText } from '..';
import { getInstallationCertificatePath } from '../../services/endpoints';

const Container = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    line-height: ${theme.fontSize.base * 1.375}px;
  `}
`;

const Link = styled.a`
  ${({ theme }) =>
  theme &&
  css`
    display: inline-block;
  
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.bold};

    text-transform: uppercase;

    margin: ${theme.gutter / 3}px 0 0 0;
    padding: 0;
  `}
`;

export default ({ window, certificateParams }) => {
  const { t } = useTranslation();
  const registeredDate = window.registeredDate;
  const registeredAddress = `${window.address}, ${window.city} ${window.postCode}`;

  return (
    <Container>
      <p><strong>{t('windowInformationsRegisteredOn')}:</strong> {registeredDate}</p>
      <p><strong>{t('windowInformationsAddress')}:</strong> {registeredAddress}</p>
      <PrimaryText withoutWhiteSpace><Link href={`${getInstallationCertificatePath(certificateParams)}`} rel="noopener noreferrer" target="_blank">{t('downloadGuarantee')}</Link></PrimaryText>
    </Container>
  );
};
