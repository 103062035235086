import colors from './colors';

export default {
  colors,
  letterSpacing: '-0.36px',
  fontSize: {
    base: 16,
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },

  breakpoints: {
    xsm: '460px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
  },

  gutter: 32,

  containers: {
    xl: '1256px',
  },
};
