import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Heading,
  Company,
  Contact,
  ContactItem,
  Icon
} from './ContactSidebarInformations.styled';

export default () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Heading type='h3'>{t('contactInformationsHeading')}</Heading>
      <Company>{t('companyName')}</Company>
      <Contact>
        <ContactItem>
          <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.26 15h-4.52a2.91 2.91 0 00-2.21.57 1.9 1.9 0 00-.53 1.36A8 8 0 017.07 10a1.9 1.9 0 001.36-.52A2.91 2.91 0 009 7.26V2.74A2.91 2.91 0 008.43.53 1.94 1.94 0 007 0H3a3 3 0 00-3 3v4a17 17 0 0017 17h4a3 3 0 003-3v-4a1.94 1.94 0 00-.53-1.43 3 3 0 00-2.21-.57zm.74 6a1 1 0 01-1 1h-4A15 15 0 012 7V3a1 1 0 011-1h4a5.72 5.72 0 010 .73v4.54A5.72 5.72 0 017 8H5v1a10 10 0 0010 10h1v-2a5.72 5.72 0 01.73 0h4.54a5.72 5.72 0 01.73 0z" /></Icon>
          {t('contactPhoneNumber')}
        </ContactItem>
        <ContactItem>
          <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.99 21.53"><path d="M8 0a8 8 0 00-8 8c0 4.12 6.5 11.8 7.24 12.66l.75.88.76-.88C9.49 19.79 16 12.11 16 8a8 8 0 00-8-8zm0 18.43C5.81 15.75 2 10.53 2 8a6 6 0 0112 0c0 2.53-3.83 7.75-6 10.43zM8 4a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z" /></Icon>
          {t('contactAddress')}
        </ContactItem>
        <ContactItem>
          <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20.91"><path d="M0 0v20.91h1.82v-8.18h10.91V6.36h5.45v7.28H9.09v1.81H20V4.55h-7.27V1.82H1.82V0zm10.91 3.64v7.27H1.82V3.64z" /></Icon>
          {t('conatctLanguage')}
        </ContactItem>
      </Contact>
    </Container>
  );
};
