import styled, { css } from 'styled-components';
import { HeadingTemplate } from '../../components'

export const Background = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  p {
    ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * 1.125}px;
      font-weight: ${theme.fontWeight.regular};
      line-height: ${theme.fontSize.base * 1.375}px;

      margin-top: ${theme.gutter * .7}px;
      padding-left: 0;

      @media (min-width: ${theme.breakpoints.lg}) {
        /* padding-left: ${theme.gutter * 2}px; */
        padding-right: ${theme.gutter * 2}px;
      }
    `}
  }

  /* div {
    ${({ theme }) =>
    theme &&
    css`
      margin-top: ${theme.gutter * 1.5}px;
    `}

    ${({ withoutMargin }) =>
    withoutMargin &&
    css`
      margin: 0;
    `}
  } */

  ${({ theme }) =>
  theme &&
  css`
    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeight.black};
      text-transform: uppercase;
    }

    h1 {
      font-size: ${theme.fontSize.base * 3.75}px;
      line-height: ${theme.fontSize.base * 2.6}px;
    }
    h2 {
      font-size: ${theme.fontSize.base * 3}px;
      line-height: ${theme.fontSize.base * 2.6}px;
    }
    h3 {
      font-size: ${theme.fontSize.base * 2}px;
      line-height: ${theme.fontSize.base * 1.75}px;
    }
    h4 {
      font-size: ${theme.fontSize.base * 1.5}px;
      line-height: ${theme.fontSize.base * 1.375}px;
    }
    h5 {
      font-size: ${theme.fontSize.base * 1.25}px;
      line-height: ${theme.fontSize.base * 1.25}px;
    }
    h6 {
      font-size: ${theme.fontSize.base}px;
      line-height: ${theme.fontSize.base * 1.25}px;
    }
  `}
`;