import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider } from 'styled-components';

import App from './App';
import GlobalStyles from './theme/GlobalStyles';

import './languages/i18n';
import './assets/fonts/fonts.css';

import theme from './theme';
import store from './store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <Suspense fallback="loading">
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </StoreProvider>
  </Suspense>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
