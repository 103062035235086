import styled, { css } from 'styled-components';

export const Background = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    background-color: ${theme.colors.gray200};
  `}
`;
export const Container = styled.div`
  min-height: calc(100vh - 100px);
  padding: ${({ theme }) => theme.gutter * 2.5}px 0;
`;

export const Box = styled.div`
  max-width: 800px;
  margin: 0 auto;

  ${({ theme }) =>
  theme &&
  css`
    padding: ${theme.gutter * 2.5}px ${theme.gutter * .75}px;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding: ${theme.gutter * 2.5}px ${theme.gutter * 1.25}px;
    }
  `}

  ${({ bottomPadding, theme }) =>
  theme &&
  !bottomPadding &&
  css`
    padding-bottom: 0;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-bottom: 0;
    }
  `}

  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .12);
`;