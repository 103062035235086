import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background-color: #fff;
`;

export const LoaderBox = styled.div`
  position: relative;
`;

export const LoaderCircle = styled.div`
  position: relative;
  margin: 0 auto;
  border: 6px solid ${({ theme }) => theme.colors.secondary};
  transform: translateZ(0);
  animation: kf-loading 1.1s linear infinite;
  border-left-color: ${({ theme }) => theme.colors.primary};
  width: 120px;
  height: 120px;
  border-radius: 50%;

  :after {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  @keyframes kf-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  svg {
    width: 51px;
    height: 60px;
  }
`;