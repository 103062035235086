import styled, { css } from 'styled-components';
import HeadingTemplate from '../Typography/HeadingTemplate';

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.gutter * 1.5}px;
  padding-bottom: ${({ theme }) => theme.gutter * 2}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-top: ${({ theme }) => theme.gutter * 2.5}px;
    padding-bottom: ${({ theme }) => theme.gutter * 3}px;
  }
`;
export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 3}px;
    font-weight: ${theme.fontWeight.black};
    line-height: ${theme.fontSize.base * 2.6}px;
    text-transform: uppercase;

    margin-bottom: ${theme.gutter * 1.25}px;
  `}
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;