export const AUTH_TOKEN_KEY = 'dakeaNetAuthKey';
export const addAuthorizationHeader = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const header = {
    'Authorization': `Bearer ${token}`
  };
  return header || {};
}

export const isTokenAvailableInStorage = () => {
  return !!localStorage.getItem(AUTH_TOKEN_KEY);
}

export const setAuthorizationToken = token => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const clearAuthorizationToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}