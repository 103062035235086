import React from 'react';
import { Container, Heading, Content } from './Page.styled';

export default ({ title, children }) => {
  return (
    <Container>
      <Heading type='h2'>{ title }</Heading>
      <Content>
        { children }
      </Content>
    </Container>
  );
};
