import React from 'react';
import styled from 'styled-components';

const Text = styled.span`
  font-style: normal;
  color: ${({ theme }) => theme.colors.primary};

  a {
    &:hover {
      color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`;

export default ({ children, withoutStartWhiteSpace, withoutEndWhiteSpace, withoutWhiteSpace }) => {
  if (withoutStartWhiteSpace) {
    return <Text>{ children }&nbsp;</Text>;
  } else if (withoutEndWhiteSpace) {
    return <Text>&nbsp;{ children }</Text>;
  } else if (withoutWhiteSpace) {
    return <Text>{ children }</Text>;
  }
  
  return <Text>&nbsp;{ children }&nbsp;</Text>
};
