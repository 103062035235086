import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import i18n from 'i18next';

import Accordion from '../Accordion/Accordion';
import RegisteredWindowInformations from '../RegisteredWindowInformations/RegisteredWindowInformations';
import { Menu, MenuItem, Container } from './RegisteredWindowsList.styled';
import WindowAccessories from '../WindowAccessories/WindowAccessories';

export default () => {
  const user = useStoreState(state => state.user);
  const products = useStoreState(state => state.products);

  const parseRegisteredDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  return (
    <Container>
      { user && user.installations && <Menu>
        { user.installations.map(installation => {
          return installation.serial_number.map(window => (
            <MenuItem key={window.id}>
              <Accordion
                heading={`${window.window_type} ${window.window_size} ${window.window_variant}`}
                serial={`#${window.number}`}
              >
                <RegisteredWindowInformations
                  window={{
                    registeredDate: parseRegisteredDate(installation.date_created),
                    address: `${installation.address_line1} ${installation.address_line2}`,
                    city: installation.towncity,
                    postCode: installation.postcode
                  }}
                  certificateParams={{
                    lang: i18n.language,
                    cert_token: window.cert_token
                  }}
                />
              </Accordion>
            </MenuItem>
          ))
        }) }
      </Menu>}
      <WindowAccessories products={products} />
    </Container>
  );
};
