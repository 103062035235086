import React from 'react';

import { Container, Heading, SubHeading, Content, ErrorIcon } from './AuthTemplate.styled';

export default ({ heading, subheading, children, error }) => {
  return (
    <Container>
      { error && (
        <ErrorIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>notice_icon</title><path d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.52,12.52,0,0,0,12.5,0Zm0,2a10.41,10.41,0,0,1,6.68,2.41L4.41,19.18A10.49,10.49,0,0,1,12.5,2Zm0,21a10.41,10.41,0,0,1-6.68-2.41L20.59,5.82A10.49,10.49,0,0,1,12.5,23Z"/></svg>
        </ErrorIcon>
      )}
      <Heading type='h2'>{ heading }</Heading>
      <SubHeading>{ subheading }</SubHeading>
      <Content>
        { children }
      </Content>
    </Container>
  );
};
