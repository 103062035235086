import React from 'react';
import Select from 'react-select';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

import colors from '../../theme/colors';

export default () => {
  const langs = useStoreState(state => state.langs);
  const supportedLangs = langs.map(lang => ({
    label: lang.slug.toUpperCase() === 'CS' ? 'CZ' :
           lang.slug.toUpperCase() === 'BF' ? 'BE-FR' :
           lang.slug.toUpperCase() === 'BN' ? 'BE-NL' :
           lang.slug.toUpperCase(),
    value: lang.slug
  }));

  const { i18n } = useTranslation();
  const handleChange = ({ value }, { action }) => {
    switch (action) {
      case 'select-option':
        i18n.changeLanguage(value)
        break;
      default:
        break;
    }
  }

  return (
    <Select
      options={supportedLangs}
      defaultValue={find(supportedLangs, { value: i18n.language })}
      value={find(supportedLangs, { value: i18n.language })}
      onChange={handleChange}
      isSearchable={false}
      styles={{
        container: (prev) => ({
          ...prev,
          width: '64px',
        }),
        control: (prev, state) => ({
          ...prev,
          border: 0,
          borderRadius: 0,
          boxShadow: state.isFocused ? `0 0 0 1px ${colors.primaryHover}` : 0,
          backgroundColor: colors.primary,
          height: '48px',
        }),
        singleValue: prev => ({
          ...prev,
          color: 'white',
          marginLeft: 0,
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: (prev) => ({
          ...prev,
          padding: '0 6px 0 0',
          color: 'white'
        }),
        menu: prev => ({
          ...prev,
          borderRadius: 0
        }),
        option: (prev, state) => ({
          ...prev,
          backgroundColor: state.isSelected ? colors.primary : 'white',
          "&:active": {
            backgroundColor: colors.primaryHover,
            color: 'white'
          }
        })
      }}
    />
  );
};
