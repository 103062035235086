import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f5f7;
  margin-bottom: ${({ theme }) => theme.gutter / 2}px;
  padding: ${({ theme }) => theme.gutter / 2.5}px;

  p {
    ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * .92}px;
    `}
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #def7ec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 12px;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
    stroke: #057a55;
  }
`;