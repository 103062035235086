import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.gutter * .5}px;

  ${({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `}

  ${({ center }) =>
  center &&
  css`
    justify-content: center;
  `}

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ({ children, ...res }) => (
  <Container {...res}>{ children }</Container>
)