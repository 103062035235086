import React from 'react'

import { PrimaryText } from '../../components';
import { Heading, Text } from './ContactThankYou.styled';
import PageBox from '../../components/PageBox/PageBox';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <PageBox>
      <Heading><PrimaryText>{t('contactThankYouHeading1')}</PrimaryText> {t('contactThankYouHeading2')}</Heading>
      <Text>{t('contactThankYouDesc')}</Text>
    </PageBox>
  );
}