import React, { useEffect } from 'react';

import { Content, Icon } from './SuccessContent.styled';

export default ({ title, close }) => {
  useEffect(() => {
    const timeout = setTimeout(close, 3500)
    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <Content>
      <Icon>
        <svg viewBox="0 0 24 24" stroke="currentColor" fill="none">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
      </Icon>
      <p>{ title }</p>
    </Content>
  );
};