import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import PageBox from '../../components/PageBox/PageBox';
import { AuthTemplate, PrimaryText, Form, Row, Field, Button } from '../../components';
import { Forgot, Professional, FormError, ErrorIcon } from './Login.styled';

import * as loginService from '../../services/login.service';
import * as userService from '../../services/user.service';

export default () => {
  const history = useHistory();
  const { t } = useTranslation();
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);
  const setUser = useStoreActions(actions => actions.setUser);

  const handleSubmit = setError => data => {
    addLoader();
    loginService.sign(data)
      .then(userService.getUserData)
      .then(response => {
        setUser(response.results.user);
        history.push('/app');
        removeLoader();
      })
      .catch(error => {
        removeLoader();
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
      })
  }

  return (
    <PageBox withoutBottomPadding>
      <AuthTemplate
        heading={t('loginHeading')}
        subheading={<strong>{t('dontHaveAnAccount')} <PrimaryText><Link to='/register'>{t('clickHereToRegister')}</Link></PrimaryText></strong>}
      >
        <Form
          onSubmit={handleSubmit}
        >
          {({ register, control, watch, errors, formState }) => {
            return (
              <>
                { errors.form && <FormError>
                  <ErrorIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>notice_icon</title><path d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.52,12.52,0,0,0,12.5,0Zm0,2a10.41,10.41,0,0,1,6.68,2.41L4.41,19.18A10.49,10.49,0,0,1,12.5,2Zm0,21a10.41,10.41,0,0,1-6.68-2.41L20.59,5.82A10.49,10.49,0,0,1,12.5,23Z"/></svg>
                  </ErrorIcon>
                  <p>{t('logInError')}</p>
                </FormError> }
                <Row>
                  <Field
                    error={errors.email}
                  >
                    <input
                      id='email'
                      name='email'
                      ref={register}
                      placeholder={t('emailAddress')}
                    />
                  </Field>
                </Row>
                <Row>
                  <Field
                    error={errors.password}
                  >
                    <input
                      id='password'
                      name='password'
                      type='password'
                      ref={register}
                      placeholder={t('password')}
                    />
                  </Field>
                </Row>
                <Row />
                <Row center>
                  <Button type='submit' text={t('logInButtonText')} wider md primary loading={formState.isSubmitting} />
                </Row>
              </>
            )
          }}
        </Form>
        <Forgot>
          <PrimaryText><Link to='/password'>{t('loginForgotPasswordQuestion')}</Link></PrimaryText>
        </Forgot>
      </AuthTemplate>
      <Professional>
        <p>{t('loginAreYouProfessionalQuestion')}</p>
        <PrimaryText><a href={t('loginClickHereUrl')}><strong>{t('clickHere')}</strong></a></PrimaryText>
      </Professional>
    </PageBox>
  );
};
