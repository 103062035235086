import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.lg}) {
      padding-right: ${theme.gutter * 2}px;
    }
  `}
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;

  margin: 0;

  ${({ theme }) =>
  theme &&
  css`
    background-color: ${theme.colors.gray200};
    padding: ${theme.gutter / 3}px 0 ${theme.gutter}px 0;
  `}
`;
export const MenuItem = styled.li`
  ${({ theme }) =>
  theme &&
  css`
    position: relative;
    padding: ${theme.gutter / 4}px 0 ${theme.gutter / 4}px ${theme.gutter * .85}px;

    button {
      font-size: ${theme.fontSize.base}px;
      font-weight: ${theme.fontWeight.black}; 
    }
  `}

  ${({ active, theme }) =>
  active &&
  theme &&
  css`
    a {
      color: ${theme.colors.primary};
    }

    &::before {
      content: "";
      display: block;
      width: ${theme.gutter / 2}px;
      height: ${theme.gutter / 2}px;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      background-color: ${theme.colors.primary};
    }
  `}
`;
export const MenuItemLink = styled(Link)`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.black}; 
  `}
`;

export const RegisterLink = styled(Link)`
  ${({ theme }) =>
  theme &&
  css`
    display: inline-block;

    font-size: ${theme.fontSize.base * 1.125}px;
    font-weight: ${theme.fontWeight.black};
    text-align: center;
    text-transform: uppercase;

    width: 100%;
    height: ${theme.gutter * 1.5}px;
    line-height: ${theme.gutter * 1.5}px;

    color: white;

    background-color: ${theme.colors.primary};

    &:hover {
      background-color: ${theme.colors.primaryHover};
    }
  `}
`;