import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { compare } from 'fast-json-patch';

import { Form, Field, Row, Button, Column } from '../';
import { updateUser, getUserData } from '../../services/user.service';
import SuccessContent from '../SuccessContent/SuccessContent';

export default () => {
  const { t } = useTranslation();
  const [successResponseStatus, setSuccessResponseStatus] = useState(false);
  const user = useStoreState(store => store.user);
  const setUser = useStoreActions(actions => actions.setUser);
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);

  const handleSubmit = setError => data => {
    const editableUser = JSON.parse(JSON.stringify(user, ['first_name', 'surname', 'email', 'phone_number']))
    const patchBody = compare(editableUser, data);

    if (patchBody.length === 0) return;
    
    addLoader();
    updateUser(patchBody)
      .then(response => {
        setUser(response.results.user);
        removeLoader();
        setSuccessResponseStatus(true);
      })
      .catch(error => {
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
        removeLoader();
      })
  }

  return (
    <Column>
      { successResponseStatus && <SuccessContent title={t('successProfileUpdate')} close={() => setSuccessResponseStatus(false)} />}
      <Form
        onSubmit={handleSubmit}
        config={{
          defaultValues: {
            first_name: user.first_name || '',
            surname: user.surname || '',
            email: user.email || '',
            phone_number: user.phone_number || ''
          }
        }}
      >
        {({ register, control, watch, errors }) => {
          return (
            <>
              <Row>
                <Field
                  label={t('firstName')}
                  id='first-name'
                  required
                  error={errors.first_name}
                >
                  <input
                    id='first-name'
                    name='first_name'
                    ref={register}
                    placeholder={t('firstName')}
                  />
                </Field>
                <Field
                  label={t('surname')}
                  id='surname'
                  required
                  error={errors.surname}
                >
                  <input
                    id='surname'
                    name='surname'
                    ref={register}
                    placeholder={t('surname')}
                  />
                </Field>
              </Row>
              <Row>
                <Field
                  label={t('emailAddress')}
                  id='email'
                  required
                  error={errors.email}
                >
                  <input
                    id='email'
                    name='email'
                    ref={register}
                    placeholder={t('emailAddress')}
                  />
                </Field>
                <Field
                  label={t('phoneNumber')}
                  id='phone-number'
                  error={errors.phone_number}
                >
                  <input
                    id='phone-number'
                    name='phone_number'
                    ref={register}
                    placeholder={t('phoneNumber')}
                  />
                </Field>
              </Row>
              <Row>
                <Button type='submit' text={t('saveButtonText')} primary md />
              </Row>
            </>
          );
        }}
      </Form>
    </Column>
  );
};
