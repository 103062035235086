import React from 'react';
import { useTranslation } from 'react-i18next';

import { Logo, Signet, Logotype } from './Logo.styled';

import logo from '../../assets/images/logo.svg';

export default ({ onBlackBackground, withoutSlogan }) => {
  const { t } = useTranslation();

  return (
    <Logo to='/'>
      <Signet primaryColor={onBlackBackground} viewBox="0 0 40.26 48">
        <polygon points="33.51 34.34 33.87 34.34 33.87 34.06 32.86 34.06 32.86 34.34 33.21 34.34 33.21 35.43 33.51 35.43 33.51 34.34"/>
        <polygon points="31.74 6.19 34.84 0 31.74 0 28.65 6.19 31.74 6.19"/>
        <path d="M30.13,38.06a2.75,2.75,0,0,0-.95.15,1.84,1.84,0,0,0-.65.4,1.43,1.43,0,0,0-.38.57,1.78,1.78,0,0,0-.12.68,2.37,2.37,0,0,0,.13.81,1.85,1.85,0,0,0,.41.64,1.76,1.76,0,0,0,.64.41,2.25,2.25,0,0,0,.84.14,1.82,1.82,0,0,0,.8-.14,2,2,0,0,0,.58-.42v.5h1.83v-4a2,2,0,0,0-.7-1.7,3.22,3.22,0,0,0-2-.55,4.9,4.9,0,0,0-.7,0,2.68,2.68,0,0,0-.59.14,2.34,2.34,0,0,0-.5.25,3.12,3.12,0,0,0-.47.39l1.17,1.17a1.75,1.75,0,0,1,.43-.32,1.41,1.41,0,0,1,.57-.09,1.19,1.19,0,0,1,.71.17.73.73,0,0,1,.22.61v.16Zm1.26,1.42a.79.79,0,0,1-.19.62.75.75,0,0,1-.31.19,1.69,1.69,0,0,1-.43,0,.61.61,0,0,1-.47-.16.46.46,0,0,1-.16-.36.44.44,0,0,1,.17-.35.59.59,0,0,1,.43-.14h1Z"/>
        <polygon points="35.61 1.55 34.06 4.64 37.16 4.64 37.16 44.9 3.1 44.9 3.1 4.64 27.87 4.64 29.42 1.55 0 1.55 0 48 40.26 48 40.26 1.55 35.61 1.55"/>
        <polygon points="35.46 35.43 35.46 34.06 35.16 34.06 34.77 34.79 34.38 34.06 34.08 34.06 34.08 35.43 34.38 35.43 34.38 34.62 34.63 35.12 34.9 35.12 35.16 34.62 35.16 35.43 35.46 35.43"/>
        <path d="M16.44,41.8v-4a2.06,2.06,0,0,0-.7-1.7,3.22,3.22,0,0,0-2-.55,4.9,4.9,0,0,0-.7,0,2.68,2.68,0,0,0-.59.14,2.34,2.34,0,0,0-.5.25,3.12,3.12,0,0,0-.47.39l1.18,1.17a1.56,1.56,0,0,1,.42-.32,1.41,1.41,0,0,1,.57-.09,1.19,1.19,0,0,1,.71.17.76.76,0,0,1,.22.61v.16H13.3a2.71,2.71,0,0,0-1,.15,1.84,1.84,0,0,0-.65.4,1.43,1.43,0,0,0-.38.57,2,2,0,0,0-.13.68,2.38,2.38,0,0,0,.14.81,1.72,1.72,0,0,0,.41.64,1.76,1.76,0,0,0,.64.41,2.21,2.21,0,0,0,.84.14,1.82,1.82,0,0,0,.8-.14,2,2,0,0,0,.58-.42v.5Zm-1.88-2.32a.79.79,0,0,1-.19.62.75.75,0,0,1-.31.19,1.69,1.69,0,0,1-.43,0,.61.61,0,0,1-.47-.16.47.47,0,0,1,0-.71.63.63,0,0,1,.44-.14h1Z"/>
        <path d="M27.72,38.48a3.6,3.6,0,0,0-.19-1.15A2.66,2.66,0,0,0,27,36.4a2.57,2.57,0,0,0-.88-.63,3,3,0,0,0-1.19-.23,2.9,2.9,0,0,0-1.12.21,2.5,2.5,0,0,0-.87.61,3.15,3.15,0,0,0-.57,1,4,4,0,0,0-.21,1.35,4.27,4.27,0,0,0,.27,1.62,2.38,2.38,0,0,0,.69,1,2.24,2.24,0,0,0,.94.46,4.7,4.7,0,0,0,1,.13,4.4,4.4,0,0,0,.77-.07,2.44,2.44,0,0,0,.63-.19,2.5,2.5,0,0,0,.53-.3,5.76,5.76,0,0,0,.48-.42l-1.13-1.12a2,2,0,0,1-.52.38,1.72,1.72,0,0,1-.76.14,1.06,1.06,0,0,1-.81-.3,1,1,0,0,1-.28-.68h3.71ZM24,38.08a1.06,1.06,0,0,1,0-.25,1.44,1.44,0,0,1,.08-.25.93.93,0,0,1,.29-.34.84.84,0,0,1,.53-.14.85.85,0,0,1,.53.14,1,1,0,0,1,.29.34c0,.09.06.17.08.25a2.11,2.11,0,0,1,0,.25Z"/>
        <path d="M10.77,41.8V34.06H8.89v2a1.62,1.62,0,0,0-.59-.38,2.11,2.11,0,0,0-.79-.13,2.37,2.37,0,0,0-.85.15A2,2,0,0,0,6,36.1a1.54,1.54,0,0,0-.34.54,2.7,2.7,0,0,0-.17.65,5.51,5.51,0,0,0-.06.71v1.41a5.36,5.36,0,0,0,.06.71,2.91,2.91,0,0,0,.17.66,1.42,1.42,0,0,0,.34.53,1.68,1.68,0,0,0,.67.41,2.38,2.38,0,0,0,.85.16,2,2,0,0,0,.81-.15A2.1,2.1,0,0,0,9,41.3v.5Zm-1.9-2.48a1.48,1.48,0,0,1-.11.46.6.6,0,0,1-.24.3.73.73,0,0,1-.42.1.78.78,0,0,1-.43-.1.73.73,0,0,1-.24-.3,1.49,1.49,0,0,1-.1-.46,5.79,5.79,0,0,1,0-.61,5.53,5.53,0,0,1,0-.61,1.56,1.56,0,0,1,.1-.47.7.7,0,0,1,.24-.29.78.78,0,0,1,.43-.11.72.72,0,0,1,.42.11.57.57,0,0,1,.24.29,1.55,1.55,0,0,1,.11.47c0,.18,0,.38,0,.61S8.88,39.13,8.87,39.32Z"/>
        <polygon points="16.87 34.06 16.87 41.8 18.75 41.8 18.75 39.94 19.12 39.54 20.41 41.8 22.75 41.8 20.41 38.18 22.69 35.61 20.42 35.61 18.75 37.77 18.75 34.06 16.87 34.06"/>
      </Signet>
      { withoutSlogan ? null : <h1>{t('productRegistrationSystem')}</h1>}
    </Logo>
  )
};
