import React from 'react';
import { useForm } from 'react-hook-form';

import { Container, Form } from './Form.styled';

export default ({ onSubmit, children: render, config }) => {
  const { register, handleSubmit, control, watch, errors, triggerValidation, formState, setError, clearError, getValues } = useForm({
    ...config
  });

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit(setError))}>
        { render({ register, control, watch, errors, triggerValidation, formState, clearError, getValues }) }
      </Form>
    </Container>
  );
};
