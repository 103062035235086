import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components';
import { Link } from '../../components/Link/Link.styled';
import { Background, Content, Container, Left, Right, Heading, Text } from './Choose.styled';
import chooseLeftBackground from '../../assets/images/choose_left.jpg';
import chooseRightBackground from '../../assets/images/choose_right.jpg';
import WindowAccessories from "../../components/WindowAccessories/WindowAccessories";

export default withTheme(({ theme }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const mql = window.matchMedia(`(min-width: ${theme.breakpoints.lg})`);
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const leftContentRef = useRef(null);
  const rightContentRef = useRef(null);

  const handleResize = (event) => {
    leftContentRef.current.style.height = null;
    rightContentRef.current.style.height = null;

    const leftContentHeight = leftContentRef.current.clientHeight;
    const rightContentHeight = rightContentRef.current.clientHeight;

    let bestHeight = 0;
    if (leftContentHeight > rightContentHeight) {
      bestHeight = leftContentHeight;
    } else if (leftContentHeight < rightContentHeight) {
      bestHeight = rightContentHeight;
    } else {
      bestHeight = rightContentHeight;
    }

    if (event.target.innerWidth <= 768) {
      leftContentRef.current.style.height = '100%';
      rightContentRef.current.style.height = '100%';
    } else {
      leftContentRef.current.style.height = `${bestHeight}px`;
      rightContentRef.current.style.height = `${bestHeight}px`;
      leftRef.current.style.minHeight = `${bestHeight}px`;
      rightRef.current.style.minHeight = `${bestHeight}px`;
    }
  };

  useEffect(() => {
    handleResize({ target: { innerWidth: window.innerWidth } });
  });

  return (
    <Background>
      <Container>
        <Left ref={leftRef} backgroundImage={chooseLeftBackground}>
          <Content ref={leftContentRef}>
            <Heading type={mql.matches ? 'h2' : 'h3'}>{t('chooseHomeownerHeading')}</Heading>
            <Text>{t('chooseHomeownerDescription')}</Text>
            <Button text={t('learMoreButtonText')} md primary handleClick={e => history.push('/home')} />

            {t('chooseHomeownerDescription_shopLink')
            && (<>
              <Text style={{"marginTop":'32px'}}>{t('chooseHomeownerDescription_shop')}</Text>
              <Button text={t('chooseHomeownerDescription_shopButton')} md primary handleClick={() => {window.location=t('chooseHomeownerDescription_shopLink')}} />
            </>)}
          </Content>
        </Left>
        <Right ref={rightRef} backgroundImage={chooseRightBackground}>
          <Content ref={rightContentRef} primary data-shopLink={t('chooseHomeownerDescription_shopLink')}>
            <Heading type={mql.matches ? 'h2' : 'h3'}>{t('chooseProfessionalHeading')}</Heading>
            <Text>{t('chooseProfessionalDescription')}</Text>
            <Link href={t('chooseProfessionalLink')} md>{t('learMoreButtonText')}</Link>
          </Content>
        </Right>
      </Container>
    </Background>
  );
});
