import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  ${({ withPadding, theme }) =>
  withPadding &&
  theme &&
  css`
    padding: 0 ${theme => theme.gutter / 2}px;
  `}

  ${({ withoutCenter }) =>
  withoutCenter &&
  css`
    margin: 0;
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 ${({ theme }) => theme.gutter / 2}px;
  }
`;

export default ({ children, ...res }) => (
  <Wrapper {...res}>
    { children }
  </Wrapper>
);
