import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    margin-top: ${theme.gutter * 1.4}px;
  `}
`;

export const SliderSubHeading = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * 1.25}px;
      font-weight: ${theme.fontWeight.black};
      padding: ${theme.gutter * .55}px 0;
    `}
`;

export const StaticProductsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) =>
  theme &&
  css`
    margin: 0 -${theme.gutter / 2.6}px;
    .product__preview {
      margin: 0 ${theme.gutter / 2.6}px ${theme.gutter / 2}px ${theme.gutter / 2.6}px;
    }
  `}
`;