import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.fieldset`
  ${({ theme }) =>
  theme &&
  css`
    padding: 0 0 ${theme.gutter * .62}px 0;
    margin: 0 0 ${theme.gutter * .62}px 0;

    border: 0;
    border-bottom: 1px solid ${theme.colors.gray100};
  `}

  ${({ border }) =>
  border &&
  css`
    border: 0;
  `}
`;

export default ({ children, withoutSeparator = false }) => (
  <Container border={withoutSeparator}>{ children }</Container>
);
