import styled, { css } from 'styled-components';
import { HeadingTemplate } from '../../components';

export const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.gray200};
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Half = styled.div`
  flex: 1;
  flex-basis: 50%;
  display: none;

  ${({ theme }) =>
  theme &&
  css`
    &:first-child {
      display: block;
      padding: ${theme.gutter * 2}px ${theme.gutter * 1.5}px ${theme.gutter * 2}px 0;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      display: block;
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      &:first-child {
        padding: ${theme.gutter * 3.5}px ${theme.gutter * 1.5}px ${theme.gutter * 3.5}px 0;
      }
    }
  `}
`;

export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
    theme &&
    css`
    margin-bottom: ${theme.gutter}px;
  `}
`;

export const PrimaryText = styled.em`
  font-style: normal;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Paragraph = styled.p`
  ${({ theme }) =>
    theme &&
    css`
    font-size: ${theme.fontSize.base * 1.25}px;
    line-height: ${theme.fontSize.base * 1.5}px;

    margin-bottom: ${theme.gutter / 2}px;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  padding-left: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: ${({ theme }) => theme.gutter}px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: ${({ theme }) => theme.gutter * 2}px;
  }
`;
export const ImageWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: auto;

  /* position: relative; */
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    height: 100%;
  }
`;
export const Image = styled.img`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    top: 50%;
    right: 0;

    max-width: 960px;
    width: 50%;
    height: auto;

    transform: translateY(-50%);
  }
`;