import React from 'react';
import { useTranslation } from 'react-i18next';

import { Background, Container } from './Terms.styled';
import { Wrapper, Page } from '../../components';

export default () => {
  const { t } = useTranslation();

  return (
    <Background>
      <Wrapper>
        <Page
          title={t('termsHeading')}
        >
          <Container dangerouslySetInnerHTML={{ __html: t('termsContent') }}/>
        </Page>
      </Wrapper>
    </Background>
  );
};
