export default {
  name: 'dakeaDetector',

  lookup() {
    const lang = navigator.language;
    if (lang.length > 2) {
      return lang.split('-')[0];
    }
    return lang;
  },
};
