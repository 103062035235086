import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import SuccessContent from '../SuccessContent/SuccessContent';
import { Form, Field, Row, Button, Column } from '../';
import { FieldExtender } from '../Field/Field';
import { changePassword } from '../../services/user.service';

export default () => {
  const { t } = useTranslation();
  const [successResponseStatus, setSuccessResponseStatus] = useState(false);
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);

  const handleSubmit = setError => data => {
    addLoader();
    changePassword(data)
      .then(() => {
        removeLoader();
        setSuccessResponseStatus(true);
      })
      .catch(error => {
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
        removeLoader();
      })
  }

  return (
    <Column>
      { successResponseStatus && <SuccessContent title={t('successPasswordUpdate')} close={() => setSuccessResponseStatus(false)} />}
      <Form
        onSubmit={handleSubmit}
      >
        {({ register, control, watch, errors }) => {
          return (
            <>
              <Row>
                <Field
                  label={t('password')}
                  id='current-password'
                  required
                  error={errors.password}
                >
                  <input
                    id='current-password'
                    name='password'
                    ref={register}
                    placeholder={t('password')}
                    type='password'
                  />
                </Field>
                <FieldExtender />
              </Row>
              <Row>
                <Field
                  label={t('password')}
                  id='new-password'
                  required
                  error={errors.password_repeat}
                >
                  <input
                    id='new-password'
                    name='password_repeat'
                    ref={register}
                    placeholder={t('password')}
                    type='password'
                  />
                </Field>
                <FieldExtender />
              </Row>
              <Row>
                <Button type='submit' text={t('saveButtonText')} primary md />
              </Row>
            </>
          );
        }}
      </Form>
    </Column>
  );
};
