import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthTemplate, Row, PageBox, Button } from '../../components';

export default () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <PageBox>
      <AuthTemplate
        heading={t('resetPasswordErrorHeading')}
        subheading={t('resetPasswordErrorDescription')}
        error
      >
        <Row center>
          <Button text={t('logInButtonText')} md wider primary handleClick={() => history.replace('/login')} />
        </Row>
      </AuthTemplate>
    </PageBox>
  );
};
