import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

import { reset } from '../../services/password.service';

export default () => {
  const { token: reset_token, email } = useParams();
  const history = useHistory();

  useEffect(() => {
    reset({
      reset_token,
      email
    })
      .then(() => {
        history.push('/password/ty')
      })
      .catch(error => {
        history.push('/password/error')
        console.log(error)
      })
  }, []);

  return (
    <Loader visible={true} />
  );
};
