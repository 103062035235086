import React from 'react';

import { Background, Container, Box } from './PageBox.styled';
import { Wrapper } from '../../components';

export default ({ children, withoutBottomPadding = false, underBoxRender }) => {
  return (
    <Background>
      <Wrapper>
      <Container>
        <Box bottomPadding={!withoutBottomPadding}>
          { children }
        </Box>
        { underBoxRender && underBoxRender() }
      </Container>
      </Wrapper>
    </Background>
  );
}