import HttpStatus from 'http-status-codes';

import { USER_PATH, USER_PROFILE_UPDATE_PATH, AUTH_REFRESH_TOKEN_PATH, USER_PASSWORD_UPDATE_PATH } from './endpoints';
import { addAuthorizationHeader, setAuthorizationToken } from './utils';

const applyHeaders = () => ({
  'Content-Type': 'application/json',
  ...addAuthorizationHeader()
});

export const getUserData = () => new Promise(async (resolve, reject) => {
  try {
    const response = await fetch(USER_PATH, {
      method: 'GET',
      headers: { ...applyHeaders() }
    });
  
    const content = await response.json();
    if (response.status === HttpStatus.OK) {
      resolve(content);
    } else {
      reject(content);
    }
  } catch (e) {
    console.log('[ERROR] get user: ', e);
  }
})

export const updateUser = data => new Promise(async (resolve, reject) => {
  try {
    const response = await fetch(USER_PROFILE_UPDATE_PATH, {
      method: 'PATCH',
      headers: { ...applyHeaders() },
      body: JSON.stringify(data)
    });
  
    const content = await response.json();
    if (response.status === HttpStatus.OK) {
      const refreshToken = content.results.refresh_token;
      
      const freshToken = await refreshUserToken(refreshToken);
      if (!freshToken) {
        reject()
        return;
      }

      setAuthorizationToken(freshToken);
      const newUser = await getUserData();
      resolve(newUser);
    } else {
      reject(content);
    }
  } catch (e) {
    reject(e);
    console.log('[ERROR] update user: ', e);
  }
});

export const refreshUserToken = async (token) => {
  const response = await fetch(AUTH_REFRESH_TOKEN_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: token
    })
  });

  const content = await response.json();
  if (response.status === HttpStatus.OK) {
    return content.results.fresh_token;
  }
  console.log('[ERROR] refresh token: ', content);
  return null;
}

export const changePassword = data => new Promise(async (resolve, reject) => {
  try {
    const response = await fetch(USER_PASSWORD_UPDATE_PATH, {
      method: 'PATCH',
      headers: { ...applyHeaders() },
      body: JSON.stringify(data)
    });

    const content = await response.json();
    if (response.status === HttpStatus.OK) {
      resolve(content);
    } else {
      reject(content);
    }
  } catch (e) {
    reject(e);
    console.log('[ERROR] change password: ', e);
  }
});