import styled, { css } from 'styled-components';

export const Background = styled.div`
  background-color: white;
`;
export const Container = styled.div``;
export const Content = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: ${({ theme }) => theme.gutter}px;
  }
`;
export const AddAnotherButton = styled.button`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.125}px;
    font-weight: ${theme.fontWeight.bold};
    text-transform: uppercase;

    color: ${theme.colors.primary};
  `}
`;