export default {
  primary: '#f18500',
  primaryRGB: '241, 133, 0',
  primaryHover: '#be6900',
  secondary: '#000',
  disabled: '#ccc',
  gray100: '#F1F1F1',
  gray200: '#f9f9f9',
  gray300: '#eee',
  error: 'red'
}