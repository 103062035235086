import React from 'react';
import styled, { css } from 'styled-components';

const Heading = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeight.black};
      text-transform: uppercase;
    }

    h1 {
      font-size: ${theme.fontSize.base * 3.75}px;
      line-height: ${theme.fontSize.base * 2.6}px;
    }
    h2 {
      font-size: ${theme.fontSize.base * 3}px;
      line-height: ${theme.fontSize.base * 2.6}px;
    }
    h3 {
      font-size: ${theme.fontSize.base * 2}px;
      line-height: ${theme.fontSize.base * 1.75}px;
    }
    h4 {
      font-size: ${theme.fontSize.base * 1.5}px;
      line-height: ${theme.fontSize.base * 1.375}px;
    }
    h5 {
      font-size: ${theme.fontSize.base * 1.25}px;
      line-height: ${theme.fontSize.base * 1.25}px;
    }
    h6 {
      font-size: ${theme.fontSize.base}px;
      line-height: ${theme.fontSize.base * 1.25}px;
    }
  `}

  ${({ uppercase }) =>
  uppercase &&
  css`
    text-transform: uppercase;
  `}
`;

export default ({ children, type, ...res }) => {
  return (
    <Heading>
      { React.createElement(type, { ...res }, children) }
    </Heading>
  );
};
