import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, SliderSubHeading, StaticProductsRow } from './WindowAccessories.styled';
import { HeadingTemplate, ProductsCarousel } from '../';

import ProductPreview from '../ProductPreview/ProductPreview';

function WindowAccessories({ withSlider, count = 4, products }) {
  const { t } = useTranslation();

  function renderStaticProducts() {
    return (
      <StaticProductsRow>
        { Object.keys(products)
          .slice(0, count)
          .map(productSlug => (
            <ProductPreview
              key={productSlug}
              source={products[productSlug]}
              label={t(`${productSlug}-name`)}
              url={t(`${productSlug}-link`)}
            />
          )
        )}
      </StaticProductsRow>
    )
  }

  if (!products || products && !Object.keys(products).length) return null;

  return (
    <Container>
      <HeadingTemplate type='h3'>{t('inNeedOfMoreComfortHeading')}</HeadingTemplate>
      <SliderSubHeading>{t('inNeedOfMoreComfortDescription')}</SliderSubHeading>
      { withSlider ? <ProductsCarousel products={products} /> : renderStaticProducts() }
    </Container>
  );
}

export default WindowAccessories;
