import styled from 'styled-components';

export const Container = styled.div`
  .select__value-container {
    position: initial;
    padding: 0 0 0 ${({ theme }) => theme.gutter / 2}px;
  }
`;

export const SelectArrow = styled.img`
  padding: 0 ${({ theme }) => theme.gutter * .3}px 0 0;
`;