import React from 'react';

import { Container, Label } from './ProductPreview.styled';

function ProductPreview({ source, label, url }) {
  return (
    <Container className="product__preview">
      <a href={url} target="_blank">
        <div className="slide__thumbnail">
          <img src={source} />
        </div>
        <Label>{ label }</Label>
      </a>
    </Container>
  );
}

export default ProductPreview;
