import HttpStatus from 'http-status-codes';
import i18n from 'i18next';
import { AUTH_FORGOT_PASSWORD_PATH, AUTH_RESET_PASSWORD_PATH } from './endpoints';

const applyHeaders = () => ({
  'Content-Type': 'application/json'
});

export const forgot = data => new Promise(async (resolve, reject) => {

  const response = await fetch(AUTH_FORGOT_PASSWORD_PATH, {
    method: 'POST',
    headers: { ...applyHeaders() },
    body: JSON.stringify({ ...data, lang: i18n.language })
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    resolve(content);
  } else {
    const error = await response.json();
    reject(error);
  }
})

export const reset = data => new Promise(async (resolve, reject) => {
  try {
    const response = await fetch(AUTH_RESET_PASSWORD_PATH, {
      method: 'POST',
      headers: { ...applyHeaders() },
      body: JSON.stringify({ ...data, lang: localStorage.getItem('i18nextLng') })
    });
  
    if (response.status === HttpStatus.OK) {
      resolve();
    } else {
      const content = await response.json();
      reject(content);
    }
  } catch (error) {
    reject(error)
  }
});
