import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter / 2}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 33%;
    flex-shrink: 0;
    margin-bottom: 0;
    padding-right: ${({ theme }) => theme.gutter}px;
  }
`;

export default ({ children }) => {
  return (
    <Container>
      { children }
    </Container>
  );
};
