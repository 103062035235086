import styled, { css } from 'styled-components';
import HeadingTemplate from '../Typography/HeadingTemplate';

export const Container = styled.div``;
export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
  theme &&
  css`
    text-align: center;
    
    margin-bottom: ${theme.gutter * .6}px;
  `}
`;
export const SubHeading = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-weight: ${theme.fontWeight.medium};

    @media (min-width: ${theme.breakpoints.lg}) {
      padding: 0 ${theme.gutter * 5.5}px;
    }
  `}
  text-align: center;
`;
export const Content = styled.div`
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.gutter * 1.25}px;
`;

export const ErrorIcon = styled.div`
  width: 128px;
  height: 128px;
  background-color: #fde8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  margin: 0 auto ${({ theme }) => theme.gutter}px auto;
  flex-shrink: 0;

  svg {
    width: 64px;
    height: 64px;
    fill: #e02424;
  }
`;