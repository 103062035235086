import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AccountEditForm, AccountSidebar, Page, Wrapper, Sidebar, RegisteredWindowsList, AddressEditForm, PasswordEditForm } from '../../components';
import { Background } from './Account.styled';

export default ({ }) => {
  const { t } = useTranslation();

  return (
    <Background>
      <Wrapper>
        <Page
          title={t('myAccountSidebar')}
        >
          <Sidebar>
            <AccountSidebar />
          </Sidebar>
          <>
            {/* <RegisteredWindowsList /> */}
            <Route exact path='/app' component={RegisteredWindowsList} />
            <Route exact path='/app/edit/account' component={AccountEditForm} />
            <Route exact path='/app/edit/address' component={AddressEditForm} />
            <Route exact path='/app/edit/password' component={PasswordEditForm} />
          </>
        </Page>
      </Wrapper>
    </Background>
  );
};
