import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, html {
    height: 100%;
  }
  
  html {
    font-size: 62.5%;
  } 
  
  body {
    margin: 0;
    font-family: "DINOffcPro", sans-serif;
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: ${({ theme }) => theme.letterSpacing};
    background-color: black;
    color: #000;
  }
  
  button {
    font-family: inherit;
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    vertical-align: top;
  
    :hover {
      cursor: pointer;
    }
  }
  
  ul,
  ol {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: none;
  }
  
  label {
    margin-bottom: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  
  a,
  button,
  input,
  textarea,
  span,
  select {
    :focus {
      outline: 1px dashed #000;
      outline-offset: 1px;
    }
  }
  
  input[type="checkbox"],
  input[type="radio"],
  input[type="file"] {
    :focus {
      ~ label {
        outline: 1px dashed #000;
        outline-offset: 1px;
      }
    }
  }
  
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  [type=number] {
      -moz-appearance:textfield;
  }
`;

export default GlobalStyle;