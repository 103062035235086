import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { AUTH_TOKEN_KEY } from '../../services/utils';

import { Logo } from '../';

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;

  height: 100px;

  padding-left: ${({ theme }) => theme.gutter / 2}px;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.sidebar ? 'column' : 'row'};
  align-items: center;
`;
const HeaderList = styled.ul`
  display: flex;
  flex-direction: ${props => props.sidebar ? 'column' : 'row'};
  margin: 0 ${({ theme }) => theme.gutter / 1.5}px 0 0;

  ${({ withWhiteText }) =>
  withWhiteText &&
  css`
    a {
      color: white;
    }
  `}
`;
const HeaderListItem = styled.li`
  padding: 0 ${({ theme }) => theme.gutter / 1.5}px;
`;
const HeaderListItemLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${({ withWhiteText }) =>
  withWhiteText &&
  css`
    color: white;
  `}
`;

const SidebarContainer = styled.div``;
const SidebarList = styled.ul``;
const SidebarListItem = styled.li`
  padding: 0 ${({ theme }) => theme.gutter / 2}px;
  margin: ${({ theme }) => theme.gutter / 2}px 0;
`;
const SidebarListItemLink = styled(Link)`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.base * 1.45}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

function Navigation({ sidebar, whiteText, closeSidebar }) {
  const { t } = useTranslation();
  const isAuthenticated = useStoreState(state => state.auth.isAuthenticated);

  const Container = sidebar ? SidebarContainer : HeaderContainer;
  const List = sidebar ? SidebarList : HeaderList;
  const Item = sidebar ? SidebarListItem : HeaderListItem;
  const ItemLink = sidebar ? SidebarListItemLink : HeaderListItemLink;

  return (
    <Container sidebar={sidebar}>
      { sidebar && (
        <SidebarHeader>
          <Logo withoutSlogan />
        </SidebarHeader>
      )}
      <List sidebar={sidebar} withWhiteText={whiteText}>
        { !isAuthenticated ? (
          <>
            <Item><ItemLink onClick={sidebar && closeSidebar} to='/login'>{t('login')}</ItemLink></Item>
            <Item><ItemLink onClick={sidebar && closeSidebar} to='/register'>{t('register')}</ItemLink></Item>
          </>
        ) : (
          <>
            <Item><ItemLink onClick={sidebar && closeSidebar} to='/app'>{t('myAccount')}</ItemLink></Item>
            <Item><ItemLink onClick={sidebar && closeSidebar} to='/installation'>{t('registerProduct')}</ItemLink></Item>
          </>
        )}
        <Item><ItemLink onClick={()=>{window.location=t('contact_link')}} to=''>{t('contact')}</ItemLink></Item>
      </List>
    </Container>
  );
}

export default Navigation;