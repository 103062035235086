import React from 'react';

import { Container, Label, Required, WithRemove, RemoveButton } from './Field.styled';

export default ({ error, label, id, required, children, remove }) => {
  return (
    <Container error={error}>
      { label && <Label htmlFor={id}>{ label } { required && <Required>*</Required>}</Label> }
      { remove ? (
        <WithRemove>
          { children }
          <RemoveButton type="button" onClick={remove}>
            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
          </RemoveButton>
        </WithRemove>
      ) : children }
      {error && <p className="error" dangerouslySetInnerHTML={{ __html: error.message }} />}
    </Container>
  );
}

export const FieldExtender = () => <Container extender />;