import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Logo = styled(Link)`
  display: flex;
  align-items: center;

  max-width: 335px;
`;
export const Signet = styled.svg`
  width: 59px;
  height: 70px;
  flex-shrink: 0;

  margin: 0 24px 0 0;

  & + h1 {
    ${({ theme }) =>
    theme &&
    css`
      display: none;

      font-size: ${theme.fontSize.base * 1.5}px;
      font-weight: ${theme.fontWeight.black};
      line-height: 22px;
      text-transform: uppercase;

      @media (min-width: ${theme.breakpoints.sm}) {
        display: inline;
      }
    `}
  }

  ${({ primaryColor, theme }) =>
    primaryColor &&
    theme &&
    css`
    fill: ${theme.colors.primary};

    & + h1 {
      color: ${theme.colors.primary};
    }
  `}
`;
export const Logotype = styled.h1`
  
`;