import styled, { css } from 'styled-components';
import HeadingTemplate from '../Typography/HeadingTemplate';

export const Container = styled.div``;
export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
    theme &&
    css`
    margin-bottom: ${theme.gutter}px;
  `}
`;
export const Company = styled.p`
${({ theme }) =>
    theme &&
    css`
    font-size: ${theme.fontSize.base * 1.25}px;
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.fontSize.base * 1.75}px;
    text-transform: uppercase;

    margin-bottom: ${theme.gutter / 3}px;
  `}
`;
export const Contact = styled.div``;
export const ContactItem = styled.p`
  display: flex;
  align-items: center;

  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.25};

    margin-bottom: ${theme.gutter / 2 }px;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;
export const Icon = styled.svg`
  width: 20px;
  height: 20px;

  ${({ theme }) =>
  theme &&
  css`
    fill: ${theme.colors.primary};
    margin-right: ${theme.gutter / 4 }px;
  `}
`;