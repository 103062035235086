import HttpStatus from 'http-status-codes';

import { AUTH_SIGN_UP_PATH, INSTALLATION_CREATE_PATH } from './endpoints';
import { addAuthorizationHeader } from './utils';

const applyHeaders = () => ({
  'Content-Type': 'application/json'
});

export const register = (data, withAuth) => new Promise(async (resolve, reject) => {
  const URL = withAuth ? INSTALLATION_CREATE_PATH : AUTH_SIGN_UP_PATH;
  let headers = { ...applyHeaders() };

  if (withAuth) {
    headers = {
      ...headers,
      ...addAuthorizationHeader()
    }
  }

  const response = await fetch(URL, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({...data, lang: localStorage.getItem('i18nextLng')})
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    resolve(content);
  } else {
    const error = await response.json();
    reject(error);
  }
})
