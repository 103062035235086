import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  flex-basis: 100%;
  
  margin-bottom: ${({ theme }) => theme.gutter / 4}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.gutter / 2}px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ extender }) =>
  extender &&
  css`
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: block;
    }
  `}

  input, textarea {
    height: 48px;
    border: 2px solid black;

    font-size: ${({ theme }) => theme.fontSize.base * 1.125}px;
    padding: 0 ${({ theme }) => theme.gutter / 2}px;

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      outline: 0;
    }

    &::placeholder {
      font-size: ${({ theme }) => theme.fontSize.base * 1.125}px;
      color: rgba(0, 0, 0, .7);
    }
  }

  ${({ error, theme }) =>
  error &&
  theme &&
  css`
    input, textarea {
      &, &:focus {
        border-color: ${theme.colors.error};
      }
    }
  `}

  textarea {
    width: 100%;
    height: auto;
    min-height: 48px;

    resize: vertical;

    padding-top: ${({ theme }) => theme.gutter / 3}px;
  }

  .error {
    ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * .8}px;
      font-weight: ${theme.fontWeight.regular};
      line-height: ${theme.fontSize.base * 1.2}px;

      color: ${theme.colors.error};

      margin-top: ${theme.gutter / 10}px;
    `}
  }
`;
export const Required = styled.span`
  ${({ theme }) =>
  theme &&
  css`
    color: ${theme.colors.primary};
  `}
`;
export const Label = styled.label`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.bold};

    margin-bottom: ${theme.gutter / 4}px;
  `}
`;

export const WithRemove = styled.div`
  display: flex;
`;

export const RemoveButton = styled.button`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-left: -2px;
  border: 2px solid black;
  border-left: 0;

  svg {
    fill: white;
  }

  ${({ theme }) =>
  theme &&
  css`
    background-color: ${theme.colors.error};
  `}
`;