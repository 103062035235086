import React from 'react';
import { PrevArrow, NextArrow } from './ProductsCarousel';

export default {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1098,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 886,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 678,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 470,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}