import HttpStatus from 'http-status-codes';

import { AUTH_SIGN_IN_PATH } from './endpoints';
import { setAuthorizationToken } from './utils';

const applyHeaders = () => ({
  'Content-Type': 'application/json'
});

export const sign = (data) => new Promise(async (resolve, reject) => {
  const response = await fetch(AUTH_SIGN_IN_PATH, {
    method: 'POST',
    headers: { ...applyHeaders() },
    body: JSON.stringify(data)
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    setAuthorizationToken(content.token);
    resolve();
  } else {
    const error = await response.json();
    reject(error);
  }
})
