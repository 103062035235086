import React, { useState } from 'react';

import { Container, Checkbox, Label, Text, Paragraph } from './Checkbox.styled';
import { PrimaryText } from '..';

export default ({ onChange, children, required, error }) => {
  const [state, setState] = useState(false);

  const handleChange = () => {
    const newValue = !state;
    onChange && onChange(newValue);
    setState(newValue);
  }

  return (
    <Container>
      <Label onClick={handleChange}>
        <Checkbox checked={state} error={error} />
        <Paragraph>{required && <PrimaryText withoutStartWhiteSpace>*</PrimaryText>}{ children && children(Text) }</Paragraph>
      </Label>
      {error && <p className="error" dangerouslySetInnerHTML={{ __html: error.message }} />}
    </Container>
  );
};
