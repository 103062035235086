import styled, { css } from 'styled-components';
import { HeadingTemplate } from '../../components';

export const Background = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  padding: ${({ theme }) => theme.gutter * 2.5}px 0;
`;
export const Heading = styled(HeadingTemplate)`
  text-align: center;
  ${({ theme }) =>
    theme &&
    css`
    margin-bottom: ${theme.gutter * 1.5}px;
  `}
`;
export const Path = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  position: relative;


  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    ${({ dottedLine }) =>
    dottedLine &&
    css`
      &::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 140px;
        transform: translateX(-50%);

        width: 70%;
        height: 8px;

        background-image: url('${dottedLine}');
        background-repeat: repeat-x;
        background-position-x: 10px;
      }
    `}
  }
`;
export const Single = styled.div`
  flex: 1;
  ${({ theme }) =>
  theme &&
  css`
    margin: 0 ${theme.gutter / 2}px ${theme.gutter * 2}px ${theme.gutter / 2}px;
    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: 0;
    }
  `}
`;
export const Thumbnail = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  position: relative;


  svg {
    width: 148px;
    height: 176px;
    background-color: white;
    fill: black;
    box-sizing: initial;

    ${({ theme }) =>
    theme &&
    css`
      padding: 0 ${theme.gutter * 1.03}px;
      .number {
        fill: ${theme.colors.primary};
      }
    `}
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;

  ${({ theme }) =>
  theme &&
  css`

  `}
`;

export const Text = styled.p`
  text-align: center;
  text-transform: uppercase;

  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.5}px;
    font-weight: ${theme.fontWeight.black};
    line-height: ${theme.fontSize.base * 1.6}px;

    margin-top: ${theme.gutter / 2}px;
    padding: 0 ${theme.gutter * 1.5}px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 ${theme.gutter}px;
    }
  `}
`;