import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import Backend from './plugins/Backend';
import { CONTENT_PATH, CONTENT_VERSION_PATH } from '../services/endpoints';
import Detector from './plugins/Detector';

const customDetector = new LanguageDetector();
customDetector.addDetector(Detector);

i18n
  .use(Backend)
  .use(customDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: CONTENT_PATH,
      versionPath: CONTENT_VERSION_PATH
    },

    fallbackLng: 'en',
    debug: false,
    lowerCaseLng: true,
    load: 'languageOnly',

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['localStorage', 'dakeaDetector', 'navigator']
    }
  });

export default i18n;