import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageBox, PrimaryText } from '../../components';
import { Container, ErrorCode, ErrorText } from './404.styled';

export default () => {
  const { t } = useTranslation();

  return (
    <PageBox>
      <Container>
        <ErrorCode type='h1'><PrimaryText>404</PrimaryText></ErrorCode>
        <ErrorText type='h3'>{t('pageNotFoundHeading')}</ErrorText>
        <PrimaryText><Link to='/'><strong>{t('backToHomepage')}</strong></Link></PrimaryText>
      </Container>
    </PageBox>
  );
};
