import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useStoreActions } from 'easy-peasy';

import {
  Wrapper,
  Page,
  Sidebar,
  Form,
  Field,
  Button,
  Row,
  Fieldset,
  Select,
  Checkbox,
  ContactSidebarInformations
} from '../../components';
import { Background, Container } from './Contact.styled';

import { submit } from '../../services/contact.service';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);

  const handleSubmit = setError => data => {
    addLoader();
    submit(data)
      .then(() => {
        removeLoader();
        history.push('/contact/ty');
      })
      .catch(error => {
        removeLoader();
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
      })
  }

  return (
    <Background>
      <Wrapper>
        <Container>
          <Page title={t('contactHeading')}>
            <Sidebar>
              <ContactSidebarInformations />
            </Sidebar>
            <Form
              onSubmit={handleSubmit}
            >
              {({ register, control, watch, errors, clearError }) => {
                return (
                  <>
                    <Fieldset withoutSeparator>
                      <Row>
                        <Field
                          label={t('firstName')}
                          id='first-name'
                          error={errors.first_name}
                        >
                          <input
                            id='first-name'
                            name='first_name'
                            ref={register}
                            placeholder={t('firstName')}
                          />
                        </Field>
                        <Field
                          label={t('surname')}
                          id='surname'
                          error={errors.surname}
                        >
                          <input
                            id='surname'
                            name='surname'
                            ref={register}
                            placeholder={t('surname')}
                          />
                        </Field>
                      </Row>
                      <Row>
                        <Field
                          label={t('emailAddress')}
                          id='email'
                          error={errors.email}
                        >
                          <input
                            id='email'
                            name='email'
                            ref={register}
                            placeholder={t('emailAddress')}
                          />
                        </Field>
                        <Field
                          label={t('phoneNumber')}
                          id='phone'
                          error={errors.phone_number}
                        >
                          <input
                            id='phone'
                            name='phone_number'
                            ref={register}
                            placeholder={t('phoneNumber')}
                          />
                        </Field>
                      </Row>
                      <Row>
                        <Field
                          label={t('subject')}
                          id='subject'
                          error={errors.subject}
                        >
                          <Controller
                            as={
                              <Select
                                options={[
                                  { label: t('askAboutProductDetailsOrPrice'), value:'1' },
                                  { label: t('askForTraining'), value:'2' },
                                  { label: t('requestContactWithCustomerService'), value:'3' },
                                  { label: t('requestVideoContact'), value:'4' },
                                  { label: t('becomeDistributor'), value:'5' },
                                  { label: t('requestContactWithMarketing'), value:'6' },
                                  { label: t('other'), value:'' }
                                ]}
                              />
                            }
                            control={control}
                            onChange={([selected, { action }]) => {
                              clearError(['subject'])
                              return selected.value;
                            }}
                            error={errors.subject}
                            name="subject"
                          />
                        </Field>
                      </Row>
                      <Row>
                        <Field
                          label={t('message')}
                          id='message'
                          error={errors.message}
                        >
                          <textarea
                            id='message'
                            name='message'
                            rows={4}
                            ref={register}
                            placeholder={t('messagePlaceholder')}
                          />
                        </Field>
                      </Row>
                      <Row>
                        <Controller
                          as={(
                            <Checkbox error={errors.acceptance}>
                              {(Text) => (
                                <Text>{t('contactAcceptance')}</Text>
                              )}
                            </Checkbox>
                          )}
                          control={control}
                          onChange={([value]) => {
                            clearError(['acceptance']);
                            return value;
                          }}
                          name='acceptance'
                        />
                      </Row>
                    </Fieldset>
                    <Fieldset withoutSeparator>
                      <Row right>
                        <Button type='submit' text={t('sendMessageButtonText')} primary />
                      </Row>
                    </Fieldset>
                  </>
                )
              }}
            </Form>
          </Page>
        </Container>
      </Wrapper>
    </Background>
  );
}