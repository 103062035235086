import HttpStatus from 'http-status-codes';
import { CONTACT_PATH } from './endpoints';

const applyHeaders = () => ({
  'Content-Type': 'application/json'
});

export const submit = async (data) => new Promise(async (resolve, reject) => {
  const response = await fetch(CONTACT_PATH, {
    method: 'POST',
    headers: { ...applyHeaders() },
    body: JSON.stringify({...data, lang: localStorage.getItem('i18nextLng')})
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    resolve(content);
  } else {
    const error = await response.json();
    reject(error)
  }
})
