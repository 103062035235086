import React from 'react';
import { useTranslation } from 'react-i18next';

import { Background, Container } from './Policy.styled';
import { Wrapper, Page } from '../../components';

export default () => {
  const { t } = useTranslation();

  return (
    <Background>
      <Wrapper>
        <Page
          title={t('policyHeading')}
        >
          <Container dangerouslySetInnerHTML={{ __html: t('policyContent') }} />
        </Page>
      </Wrapper>
    </Background>
  );
};
