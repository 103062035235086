import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      disableStyles
      buttonText={t('cookieClose')}
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 60,
        padding: 32,
        maxWidth: 340,
        backgroundColor: 'rgba(0, 0, 0, .9)',
        color: '#fff',
        textAlign: 'center',
      }}
      buttonStyle={{
        color: '#f18500',
        backgroundColor: 'transparent',
        fontSize: 18,
        lineHeight: 22,
        display: 'inline-block',
        minWidth: 240,
        padding: '13px 32px',
        border: '2px solid #f18500',
        backgroundColor: 'transparent',
        lineHeight: 1,
        fontWeight: 900,
        textTransform: 'uppercase',
        textAlign: 'center',
        marginTop: 24
      }}
    >
      {t('cookie')} <Link to='/policy'>{t('cookiePrivacyPolicy')}</Link>.
    </CookieConsent>
  );
};
