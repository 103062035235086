import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../components';
import {
  Background,
  Container,
  Half,
  Heading,
  PrimaryText,
  Paragraph,
  ImageContainer,
  ImageWrapper,
  Image
} from './About.styled';

import aboutImage from '../../assets/images/about.jpg';

export default () => {
  const { t } = useTranslation();

  return (
    <Background>
      <Wrapper>
        <Container>
          <Half>
            <Heading type='h2'>{t('aboutHeading')}</Heading>
            <Paragraph dangerouslySetInnerHTML={{ __html: t('aboutStrongDescription') }} />
            <Paragraph dangerouslySetInnerHTML={{ __html: t('aboutDescription') }} />
          </Half>
          <Half center>
            <ImageContainer>
              <ImageWrapper>
                <Image src={aboutImage} />
              </ImageWrapper>
            </ImageContainer>
          </Half>
        </Container>
      </Wrapper>
    </Background>
  );
}