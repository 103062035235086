import React from 'react';

import Hero from '../sections/Hero/Hero';
import About from '../sections/About/About';
import HowItWorks from '../sections/HowItWorks/HowItWorks';

export default () => {
  return (
    <>
      <Hero />
      <HowItWorks/>
      <About />
    </>
  );
}