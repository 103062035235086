import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import { Button } from './Button.styled';

export default ({ text, handleClick, loading, ...res }) => {
  return (
    <Button onClick={handleClick} {...res}>
      {!loading ? text : (
        <ClipLoader
          size={30}
          color={"white"}
          loading={loading}
        />
      )}
    </Button>
  );
};