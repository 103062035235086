import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: black;
  padding: ${({ theme }) => theme.gutter / 2}px 0;
`;

export const Navigation = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;

  list-style: none;
  margin-bottom: ${({ theme }) => theme.gutter}px;

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }
  `}
`;
export const NavigationItem = styled.li`
  margin-top: ${({ theme }) => theme.gutter / 4}px;


  a {
    color: white;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 0;
      margin-right: ${({ theme }) => theme.gutter}px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  `}
`;

export const BeltContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  /* height: 90px; */
`;

export const FooterBelt = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  min-height: 24px;

  background-color: ${({ theme }) => theme.colors.primary};
`;

export const FooterBeltShape = styled.div`
    ${({ theme }) =>
    theme &&
    css`
        width: 70px;
        height: 100%;

        position: relative;
        margin-left: ${theme.gutter / 2}px;

        @media (min-width: ${theme.breakpoints.sm}) {
          width: 112px;
          margin-left: 0;
        }
        
        &::before {
          width: 48px;
          height: 50px;

          content: "";
          position: absolute;
          top: 50%;
          right: 24px;
          background-color: black;
          transform: skew(-30deg) translate3d(0, -50%, 0);
        }

        &::after {
          width: 24px;
          height: 50px;

          content: "";
          position: absolute;
          top: 50%;
          right: 36px;
          background-color: ${theme.colors.primary};
          transform: skew(-30deg) translate3d(0, -50%, 0);
        }
    `}
`;

export const FooterBeltText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base * .64}px;
  line-height: 20px;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.base * .84}px;
  }
`;

export const Copyright = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base * .84}px;
  color: ${({ theme }) => theme.colors.disabled};
  text-align: right;
  padding-right: ${({ theme }) => theme.gutter}px;
  padding-bottom: ${({ theme }) => theme.gutter / 4}px;
  margin-top: ${({ theme }) => theme.gutter / 2}px;
`;