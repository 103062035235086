import React from 'react';
import Select from 'react-select';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Container, SelectArrow } from './Select.styled';
import colors from '../../theme/colors';

import selectArrow from '../../assets/images/selectArrow.svg';

const DropdownIndicator = () => <SelectArrow src={selectArrow} />

export default ({ options, onChange, error, value }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Select
        placeholder={t('select')}
        options={options}
        onChange={onChange}
        isSearchable={false}
        classNamePrefix='select'
        components={{ DropdownIndicator }}
        value={find(options, { value })}
        styles={{
          container: (prev) => ({
            ...prev,
          }),
          valueContainer: prev => ({
            ...prev,
            height: 48
          }),
          control: (prev, state) => ({
            ...prev,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: error ? 'red' : state.isFocused ? colors.primary : 'black',
            borderRadius: 0,
            boxShadow: 0,
            height: 48,
            padding: 0,
            '&:hover': {
              boxShadow: 0
            }
          }),
          placeholder: prev => ({
            ...prev,
            fontSize: 18,
            color: 'rgba(0, 0, 0, .7)'
          }),
          singleValue: prev => ({
            ...prev,
            fontFamily: 'DINOffcPro',
            fontSize: 18,
            color: 'black',
            padding: 0
            // color: 'white'
          }),
          indicatorsContainer: prev => ({
            ...prev,
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          dropdownIndicator: (prev) => ({
            ...prev,
            padding: '0 16px 0 8px',
            color: colors.primary,
          }),
          menu: prev => ({
            ...prev,
            borderRadius: 0
          }),
          option: (prev, state) => ({
            ...prev,
            backgroundColor: state.isSelected ? colors.primary : 'white',
            "&:active": {
              backgroundColor: colors.primaryHover,
              color: 'white'
            }
          })
        }}
      />
    </Container>
  );
}