import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { Container, Menu, MenuItem, MenuItemLink, RegisterLink } from './AccountSidebar.styled';

import { clearAuthorizationToken } from '../../services/utils';

export default () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const removeUser = useStoreActions(actions => actions.removeUser);
  const isActive = name => pathname === name;

  const handleLogout = () => {
    clearAuthorizationToken();
    removeUser();
    history.replace('/login')
  }

  return (
    <Container>
      <Menu>
        <MenuItem active={isActive('/app')}><MenuItemLink to='/app'>{t('myRegisteredProductsLink')}</MenuItemLink></MenuItem>
        <MenuItem active={isActive('/app/edit/account')}><MenuItemLink to='/app/edit/account'>{t('editAccountLink')}</MenuItemLink></MenuItem>
        <MenuItem active={isActive('/app/edit/address')}><MenuItemLink to='/app/edit/address'>{t('editAddressLink')}</MenuItemLink></MenuItem>
        <MenuItem active={isActive('/app/edit/password')}><MenuItemLink to='/app/edit/password'>{t('changePasswordLink')}</MenuItemLink></MenuItem>
        <MenuItem><button onClick={handleLogout} to='/logout'>{t('logOutLink')}</button></MenuItem>
      </Menu>
      <RegisterLink to='/installation'>
        {t('registerProductButtonText')}
      </RegisterLink>
    </Container>
  );
};
