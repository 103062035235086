import React from 'react'
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';

import {
  Heading,
  Text,
  SliderWrapper,
} from './RegisterThankYou.styled';
import PageBox from '../../components/PageBox/PageBox';
import WindowAccessories from '../../components/WindowAccessories/WindowAccessories';

export default () => {
  const { t } = useTranslation();
  const products = useStoreState(state => state.products);

  return (
    <>
      <PageBox underBoxRender={() => (
        <SliderWrapper>
          <WindowAccessories products={products} withSlider />
        </SliderWrapper>
      )}>
        <Heading>{t('successRegisterHeading')}</Heading>
        <Text>{t('successRegisterDescription')}</Text>
      </PageBox>
    </>
  );
}