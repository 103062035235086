import styled, { css } from 'styled-components';

export const Container = styled.div`
  .error {
    ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * .8}px;
      font-weight: ${theme.fontWeight.regular};
      line-height: ${theme.fontSize.base * 1.2}px;

      color: ${theme.colors.error};

      margin-top: ${theme.gutter / 10}px;
    `}
  }
`;
export const Checkbox = styled.div`
  ${({ theme, checked }) =>
  theme &&
  css`
    flex-shrink: 0;
    width: ${theme.gutter / 2}px;
    height: ${theme.gutter / 2}px;
    
    border: 2px solid black;

    margin-right: ${theme.gutter / 4}px;
  `}

  ${({ checked, theme }) =>
  checked &&
  theme &&
  css`
    border-color: ${theme.colors.primary};
    background-color: ${theme.colors.primary};
  `}

  ${({ error, theme }) =>
  error &&
  theme &&
  css`
    border-color: ${theme.colors.error};
  `}
`;
export const Label = styled.div`
  display: flex;

  cursor: pointer;
`;

export const Paragraph = styled.p`
  display: inline-flex;
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * .87}px;
    line-height: ${theme.fontSize.base}px;
  `}
`;
export const Text = styled.span`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * .87}px;
    line-height: ${theme.fontSize.base}px;
  `}
`;