import React, { useState, useEffect } from 'react';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Sidebar from 'react-sidebar';
import ReactGA from 'react-ga';

import { useStoreActions, useStoreState } from 'easy-peasy';

import Home from './containers/Home';
import Login from './containers/Login/Login';
import Register from './containers/Register/Register';
import RegisterThankYou from './containers/RegisterThankYou/RegisterThankYou';
import Contact from './containers/Contact/Contact';
import ContactThankYou from './containers/ContactThankYou/ContactThankYou';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm/ResetPasswordConfirm';
import ResetPasswordError from './containers/ResetPasswordError/ResetPasswordError';
import ErrorPage from './containers/404/404';
import Policy from './containers/Policy/Policy';
import Terms from './containers/Terms/Terms';
import Account from './containers/Account/Account';
import Choose from './containers/Choose/Choose';

import { Footer, Header, Cookies } from './components';
import { Navigation } from './components';

import colors from './theme/colors';
import Loader from './components/Loader/Loader';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import * as userService from './services/user.service';
import RegisterInstallation from './containers/RegisterInstallation/RegisterInstallation';
import { isTokenAvailableInStorage } from './services/utils';

const mql = window.matchMedia(`(min-width: 800px)`);
const history = createBrowserHistory();

history.listen(_ => {
  window.scrollTo(0, 0);
});

function App() {
  const [sidebarInformations, setSidebarInformations] = useState({ sidebarDocked: mql.matches, sidebarOpen: false })
  const getLangs = useStoreActions(actions => actions.fetchLangs);
  const setUser = useStoreActions(actions => actions.setUser);
  const getProducts = useStoreActions(actions => actions.getProducts);
  const activeLoader = useStoreState(state => state.loader);
  const isAuthenticated = useStoreState(state => state.auth.isAuthenticated);
  const isAfterTokenValidation = useStoreState(state => state.auth.afterTokenValidation);

  // const checkWindowWidth = () => {
  //   setSidebarInformations({ sidebarDocked: mql.matches, sidebarOpen: false })
  // }

  useEffect(() => {
    getLangs();
    if (isTokenAvailableInStorage()) {
      userService.getUserData()
        .then(response => {
          setUser(response.results.user);
        })
        .catch(e => {
          console.log(e);
          setUser({});
        })
      getProducts();
    } else {
      setUser({});
    }

    ReactGA.initialize('UA-113955150-21');
    // checkWindowWidth({ target: { innerWidth: window.innerWidth } });
    // const windowSize = window.addEventListener('resize', checkWindowWidth, false);
    // return () => window.removeEventListener('resize', windowSize);
  }, []);

  const toggleSidebar = () => {
    setSidebarInformations({
      ...setSidebarInformations,
      sidebarOpen: !sidebarInformations.sidebarOpen
    });
  };

  const renderApp = () => {
    if (!isAuthenticated && !isAfterTokenValidation) {
      return <Loader visible={true} />
    }

    return (
      <>
        <Header
          sidebarOpen={sidebarInformations.sidebarOpen}
          hideNavigation={!sidebarInformations.sidebarDocked}
          openSidebar={toggleSidebar}
        />
        <Cookies />
        <Loader visible={activeLoader.length} />
        <Switch>
          <Route exact path='/' component={Choose} />
          <Route exact path='/home' component={Home} />

          <PrivateRoute path='/app'>
            <Account />
          </PrivateRoute>

          <PrivateRoute path='/installation'>
            <RegisterInstallation />
          </PrivateRoute>

          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/register/ty' component={RegisterThankYou} />

          <Route exact path='/password' component={ForgotPassword} />
          <Route exact path='/password/reset/:token/:email' component={ResetPassword} />
          <Route exact path='/password/ty' component={ResetPasswordConfirm} />
          <Route exact path='/password/error' component={ResetPasswordError} />

          <Route exact path='/contact' component={Contact} />
          <Route exact path='/contact/ty' component={ContactThankYou} />

          <Route exact path='/policy' component={Policy} />
          <Route exact path='/terms' component={Terms} />

          <Route path='*' component={ErrorPage} />
        </Switch>
        <Footer />
      </>
    )
  }

  return (
    <Router history={history}>
      { !sidebarInformations.sidebarDocked ? (
        <Sidebar
          sidebar={<Navigation closeSidebar={toggleSidebar} sidebar />}
          open={sidebarInformations.sidebarOpen}
          styles={{
            sidebar: {
              width: 240,
              background: colors.primary
            }
          }}
        >
          { renderApp() }
        </Sidebar>
      ) : renderApp() }
    </Router>
  );
}

export default App;
