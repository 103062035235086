import { interpolate } from "../services/endpoints";

const TERMS_FILE_NAME = 'customer_terms_{{lang}}.pdf';
const termsWithOwnLanguage = ['cs', 'en', 'fr', 'hu', 'nl', 'pl', 'ro', 'sk'];

export const getCorrectTermsFileUrl = lang => {
  let correctLang = termsWithOwnLanguage.indexOf(lang) === -1 ? 'en' : lang;
  return interpolate(TERMS_FILE_NAME, { lang: correctLang });
}

const COOKIE_FILE_NAME = 'cookie/cookie_{{lang}}.docx';
const cookieWithOwnLanguage = ['cs', 'dk', 'en', 'fr', 'hu', 'nl', 'ro', 'sk'];

export const getCoorectCookieFileUrl = lang => {
  let correctLang = cookieWithOwnLanguage.indexOf(lang) === -1 ? 'en' : lang;
  return interpolate(COOKIE_FILE_NAME, { lang: correctLang });
}