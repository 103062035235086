import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { compare } from 'fast-json-patch';
import { updateUser } from '../../services/user.service';

import SuccessContent from '../SuccessContent/SuccessContent';
import { Form, Field, Row, Select, Button, Column } from '../';
import { FieldExtender } from '../Field/Field';

import countries from '../../data/countries';

export default () => {
  const { t } = useTranslation();
  const [successResponseStatus, setSuccessResponseStatus] = useState(false);
  const user = useStoreState(store => store.user);
  const setUser = useStoreActions(actions => actions.setUser);
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);

  const handleSubmit = setError => data => {
    const editableUser = JSON.parse(JSON.stringify(user, ['address_street_number', 'address_city', 'address_postcode', 'address_country']))
    const patchBody = compare(editableUser, data);

    if (patchBody.length === 0) return;
    
    addLoader();
    updateUser(patchBody)
      .then(response => {
        setUser(response.results.user);
        removeLoader();
        setSuccessResponseStatus(true);
      })
      .catch(error => {
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            setError(invalid.field, invalid.rule, t(invalid.rule))
          })
        }
        removeLoader();
      })
  }

  return (
    <Column>
      { successResponseStatus && <SuccessContent title={t('successAddressUpdate')} close={() => setSuccessResponseStatus(false)} /> }
      <Form
        onSubmit={handleSubmit}
        config={{
          defaultValues: {
            address_street_number: user.address_street_number || '',
            address_city: user.address_city || '',
            address_postcode: user.address_postcode || '',
            address_country: user.address_country || ''
          }
        }}
      >
        {({ register, control, watch, errors }) => {
          return (
            <>
              <Row>
                <Field
                  label={t('address')}
                  id='address'
                  required
                  error={errors.address}
                >
                  <input
                    id='address'
                    name='address_street_number'
                    ref={register}
                    placeholder={t('address_street_number')}
                  />
                </Field>
              </Row>
              <Row>
                <Field
                  label={t('city')}
                  id='city'
                  required
                  error={errors.address_city}
                >
                  <input
                    id='city'
                    name='address_city'
                    ref={register}
                    placeholder={t('city')}
                  />
                </Field>
                <Field
                  label={t('postCode')}
                  id='postcode'
                  required
                  error={errors.address_postcode}
                >
                  <input
                    id='postcode'
                    name='address_postcode'
                    ref={register}
                    placeholder={t('postCode')}
                  />
                </Field>
              </Row>
              <Row>
                <Field
                  label={t('country')}
                  id='country'
                  required
                  error={errors.address_country}
                >
                  <Controller
                    as={
                      <Select
                        options={countries}
                      />
                    }
                    control={control}
                    onChange={([selected, { action }]) => {
                      return selected.value;
                    }}
                    error={errors.address_country}
                    name="address_country"
                  />
                </Field>
                <FieldExtender />
              </Row>
              <Row>
                <Button type='submit' text={t('saveButtonText')} primary md />
              </Row>
            </>
          );
        }}
      </Form>
    </Column>
  );
};
