import styled, { css } from 'styled-components';
import { HeadingTemplate } from '../../components';

export const Container = styled.div`
  text-align: center;
`;
export const ErrorCode = styled(HeadingTemplate)`
  margin-bottom: ${({ theme }) => theme.gutter * 1.5}px;
`;
export const ErrorText = styled(HeadingTemplate)`
  margin-bottom: ${({ theme }) => theme.gutter * .7}px;
`;