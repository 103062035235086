import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';

import { PrimaryText } from '../';
import { Container, MediumText, BoldText, Heading, SerialInfo, Image } from './RegisterSidebarInformations.styled';
import codeScan from '../../assets/images/codeScan.png';

export default () => {
  const { t } = useTranslation();
  const isAuthenticated = useStoreState(state => state.auth.isAuthenticated);

  return (
    <Container>
      <MediumText>{t('registerSidebarFirstParagraph')}</MediumText>
      { !isAuthenticated ? <BoldText><strong>{t('registerSidebarSecondParagraphPart1')} <Link to='/login'><PrimaryText withoutWhiteSpace>{t('registerSidebarSecondParagraphLink')}</PrimaryText></Link> {t('registerSidebarSecondParagraphPart2')}</strong></BoldText> : null }
      <BoldText><strong>{t('registerSidebarThirdParagraphPart1')} <a href={t('registerSidebarThirdParagraphUrl')}><PrimaryText withoutWhiteSpace>{t('registerSidebarThirdParagraphLink')}</PrimaryText></a></strong></BoldText>
      <SerialInfo>
        <Heading type='h4'>{t('whereFindSerialNumberHeading')}</Heading>
        <Image src={codeScan} />
      </SerialInfo>
    </Container>
  );
};
