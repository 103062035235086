import React from 'react';
import Slider from 'react-slick';
import {
  ArrowContainer,
  ArrowIconWrapper,
  SliderWrapper
} from './ProductsCarousel.styled';
import ProductPreview from '../ProductPreview/ProductPreview';

import settings from './settings';

import "../../assets/css/slick.css";
import { useTranslation } from 'react-i18next';

function Arrow({ onClick, children }) {
  return (
    <ArrowContainer>
      <ArrowIconWrapper
        onClick={onClick}
      >
        { children }
      </ArrowIconWrapper>
    </ArrowContainer>
  );
};

export function NextArrow(props) {
  return (
    <Arrow {...props}>
      <svg viewBox="0 0 8.48 14.14"><polygon points="1.41 0 8.48 7.07 1.41 14.14 0 12.72 5.65 7.07 0 1.41 1.41 0"/></svg>
    </Arrow>
  );
}

export function PrevArrow(props) {
  return (
    <Arrow {...props}>
      <svg viewBox="0 0 8.48 14.14"><polygon points="7.07 14.14 0 7.07 7.07 0 8.48 1.42 2.83 7.07 8.48 12.73 7.07 14.14"/></svg>
    </Arrow>
  );
}

function ProductsCarousel({ hideArrows, withoutDragging, products }) {
  const { t } = useTranslation();

  return (
    <SliderWrapper>
      <Slider {...settings}>
        { Object.keys(products)
          .map(productSlug => (
            <ProductPreview
              key={productSlug}
              source={products[productSlug]}
              label={t(`${productSlug}-name`)}
              url={t(`${productSlug}-link`)}
            />
          )
        )}
      </Slider>
    </SliderWrapper>
  );
}

export default ProductsCarousel;
