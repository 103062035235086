import styled, { css } from 'styled-components';
import HeadingTemplate from '../Typography/HeadingTemplate';

export const Container = styled.div``;
export const MediumText = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.fontSize.base * 1.3}px;
    
    margin-bottom: ${theme.gutter / 2}px;
  `}
`
export const BoldText = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.fontSize.base * 1.3}px;
    
    margin-bottom: ${theme.gutter / 2}px;
  `}
`;

export const SerialInfo = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    margin-top: ${theme.gutter * 1.25}px;
  `}
`;

export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
  theme &&
  css`
    margin-bottom: ${theme.gutter / 2}px;
  `}
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
  border-radius: 8px;
`;