import { Slide } from 'pure-react-carousel';
import styled, { css } from 'styled-components';
import HeadingTemplate from '../../components/Typography/HeadingTemplate';

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
`;

export const Heading = styled(HeadingTemplate)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;

  color: black;

  ${({ theme }) =>
  theme &&
  css`
    margin-bottom: ${theme.gutter}px;

    svg {
      flex-shrink: 0;
      width: ${theme.gutter * 1.5}px;
      height: ${theme.gutter * 1.5}px;

      margin-right: ${theme.gutter / 2}px;
      margin-bottom: ${theme.gutter / 2}px;

      fill: ${theme.colors.primary};
    }

    @media (min-width: ${theme.breakpoints.sm}) {
      flex-direction: row;
      align-items: center;
      svg {
        margin-bottom: 0;
      }
    }
  `}
`;
export const Text = styled.p`
  color: black;
  text-transform: uppercase;
  max-width: 570px;

  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.black};
    line-height: ${theme.fontSize.base * 1.3}px;

    padding-right: 0px;
    margin-bottom: ${theme.gutter * 1.25}px;

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.fontSize.base * 1.5}px;
      font-weight: ${theme.fontWeight.black};
      line-height: ${theme.fontSize.base * 1.6}px;

      padding-right: ${theme.gutter}px;
    }
  `}
`;

export const SloganContainer = styled.div`
  display: flex;
  align-items: center;

  /* background-color: rgba(0, 0, 0, .5); */

  height: 100%;

  z-index: 1;
`;
export const Slogan = styled.div`
  max-width: 640px;
`;

export const SlideWrapper = styled(Slide)`
  /* min-height: 100vh; */
  min-height: 100vh;
  padding: 0 !important;
  padding: ${({ theme }) => theme.gutter * 2}px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 700px;
  }
`;
export const Background = styled.div`
  width: 100%;
  height: 100%;

  background: top/cover no-repeat;

  ${({ backgroundImage }) =>
  backgroundImage &&
  css`
    background-image: url(${backgroundImage});
    background-position-x: 60%;
    background-position-y: 10%;
  `}
`;