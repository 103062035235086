import { action, thunk } from 'easy-peasy';
import { fetchEntity, fetchProducts } from '../../services/content.service';
import { CONTENT_LANGS_LIST } from '../../services/endpoints';

const storeModel = {
  loader: [],
  langs: [],
  user: {},
  auth: {
    isAuthenticated: false,
    afterTokenValidation: false
  },
  products: {},
  addLangs: action((state, payload) => {
    state.langs.push(...payload);
    state.loader.splice(0, 1);
  }),
  addActiveLoader: action((state) => {
    state.loader.push(true);
  }),
  removeActiveLoader: action((state) => {
    state.loader.splice(0, 1);
  }),
  fetchLangs: thunk(async (actions) => {
    actions.addActiveLoader()
    const response = await fetchEntity(CONTENT_LANGS_LIST);
    actions.addLangs(response.results.languages);
  }),
  setUser: action((state, payload) => {
    state.user = { ...payload };
    state.auth.isAuthenticated = !!Object.keys(payload).length;
    state.auth.afterTokenValidation = true;
  }),
  updateUserInstallations: action((state, payload) => {
    state.user = {
      ...state.user,
      installations: [
        ...state.user.installations,
        ...payload
      ]
    }
    state.loader.splice(0, 1);
  }),
  removeUser: action((state) => {
    state.user = {};
    state.auth.isAuthenticated = false;
    state.auth.afterTokenValidation = true;
  }),
  setProducts: action((state, payload) => {
    state.products = payload;
  }),
  getProducts: thunk(async (action, payload) => {
    fetchProducts()
      .then(content => {
        action.setProducts(content.results.products);
      })
      .catch(e => {
        console.log('[GET] Products error: ', e);
      })
  })
};

export default storeModel;
