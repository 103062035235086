import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Navigation, HamburgerButton, LanguagePicker, Wrapper, Logo, } from '../';

const Background = styled.div`
  background-color: white;

  ${({ withBlackBackground }) =>
  withBlackBackground &&
  css`
    background-color: black;
  `}
`;
const Container = styled.div`
  height: 100px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RightContent = styled.div`
  display: flex;
  ${({ reverse }) =>
    reverse ?
    css`
      flex-direction: row-reverse;
    ` :
    css`
      flex-direction: row;
    `}
`;

const PickerContainer = styled.div`
  margin-right: ${({ theme }) => theme.gutter / 2}px;
`;

export default ({ disableLangPicker, hideNavigation, openSidebar, sidebarOpen }) => {
  const { pathname } = useLocation();
  const blackBackground = pathname === '/home';
  return (
    <Background withBlackBackground={blackBackground}>
      <Wrapper>
        <Container>
          <Logo onBlackBackground={blackBackground} />
          <RightContent reverse={hideNavigation}>
            { !hideNavigation ? <Navigation whiteText={blackBackground} /> : <HamburgerButton open={sidebarOpen} onClick={openSidebar} /> }
            <PickerContainer>
              { !disableLangPicker && <LanguagePicker />}
            </PickerContainer>
          </RightContent>
        </Container>
      </Wrapper>
    </Background>
  );
};
