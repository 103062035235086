import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Wrapper, Page, Sidebar, Form, Field, Button, Row, Fieldset, Select, Checkbox, PrimaryText, RegisterSidebarInformations } from '../../components';
import { Background, Container, AddAnotherButton, Content } from './RegisterIntallation.styled';

import { FieldExtender } from '../../components/Field/Field';

import * as registrationService from '../../services/registration.service';
import countries from '../../data/countries';

export default () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [serialNumberInputsCount, setSerialNumberInputsCount] = useState(1);
  const addLoader = useStoreActions(actions => actions.addActiveLoader);
  const removeLoader = useStoreActions(actions => actions.removeActiveLoader);
  const updateUserInstallations = useStoreActions(actions => actions.updateUserInstallations);

  const user = useStoreState(store => store.user);

  const increaseSerialNumberInputs = (append) => {
    const count = serialNumberInputsCount + 1;
    setSerialNumberInputsCount(count);
    append(`name[${count}]`)
  }

  const handleSubmit = setError => data => {
    addLoader();
    registrationService.register(data, true)
      .then(content => {
        updateUserInstallations(content.results.installations)
        history.push('/register/ty');
      })
      .catch(error => {
        removeLoader();
        if (error && error.invalid_fields) {
          error.invalid_fields.forEach(invalid => {
            if (invalid.field === 'serial_numbers') {
              setError(`${invalid.field}${invalid.index}`, invalid.rule, t(invalid.rule))
            } else {
              setError(invalid.field, invalid.rule, t(invalid.rule))
            }
          })
        }
      })
  }

  return (
    <Background>
      <Wrapper>
        <Container>
          <Page title={t('registerProductsHeading')}>
            <Sidebar>
              <RegisterSidebarInformations />
            </Sidebar>
            <Content>
              <Form
                onSubmit={handleSubmit}
                config={{
                  defaultValues: {
                    address_line1: user.address_street_number || '',
                    address_line2: user.address_country || '',
                    towncity: user.address_city || '',
                    postcode: user.address_postcode || '',
                    serial_numbers: ['']
                  }
                }}
              >
                {({ register, control, errors, triggerValidation, formState, clearError, getValues }) => {
                  const { fields, append, remove } = useFieldArray(
                    {
                      control,
                      name: "serial_numbers",
                    }
                  );

                  return (
                    <>
                      <Fieldset>
                        <Row>
                          <Field
                            label={t('address')}
                            id='address'
                            error={errors.address_line1}
                            required
                          >
                            <input
                              id='address'
                              name='address_line1'
                              ref={register}
                              placeholder={t('address')}
                            />
                          </Field>
                        </Row>
                        <Row>
                          <Field
                            label={t('city')}
                            id='city'
                            error={errors.towncity}
                            required
                          >
                            <input
                              id='city'
                              name='towncity'
                              ref={register}
                              placeholder={t('city')}
                            />
                          </Field>
                          <Field
                            label={t('postCode')}
                            id='postcode'
                            error={errors.postcode}
                            required
                          >
                            <input
                              id='postcode'
                              name='postcode'
                              ref={register}
                              placeholder={t('postCode')}
                            />
                          </Field>
                        </Row>
                        <Row>
                          <Field
                            label={t('country')}
                            id='country'
                            error={errors.address_line2}
                            required
                          >
                            <Controller
                              as={
                                <Select
                                  options={countries}
                                />
                              }
                              control={control}
                              onChange={([selected, { action }]) => {
                                clearError(['address_line2'])
                                return selected.value;
                              }}
                              error={errors.address_line2}
                              name="address_line2"
                            />
                          </Field>
                          <FieldExtender />
                        </Row>
                      </Fieldset>
                      <Fieldset>
                        { fields.map((field, index) => (
                          <Row key={field.id}>
                            <Field
                              label={`${t('serialNumber')} #${index}`}
                              id={`serial-${index}.serial`}
                              error={errors[`serial_numbers${index}`]}
                              remove={index > 0 ? () => remove(index) : undefined}
                            >
                              <input
                                id={`serial-${index}.serial`}
                                name={`serial_numbers[${index}]`}
                                ref={register()}
                                placeholder={t('enterSerialNumber')}
                                onChange={() => clearError(`serial_numbers${index}`) && triggerValidation('serial_numbers')}
                              />
                            </Field>
                            <FieldExtender />
                          </Row>
                        ))}
                        <Row>
                          <AddAnotherButton
                            type='button'
                            onClick={() => increaseSerialNumberInputs(append)}
                          >+ {t('addAnotherSerialNumber')}</AddAnotherButton>
                        </Row>
                      </Fieldset>
                      <Fieldset withoutSeparator>
                        <Row right>
                          <Button
                            primary
                            text={t('registerButton')}
                            type='submit'
                            loading={formState.isSubmitting}
                          />
                        </Row>
                      </Fieldset>
                    </>
                  )
                }}
              </Form>
            </Content>
          </Page>
        </Container>
      </Wrapper>
    </Background>
  );
}