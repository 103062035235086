import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 185px;
  height: 220px;

  .slide__thumbnail {
    position: relative;
    overflow: hidden;
    height: 184px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 220px;
    transform: translate(-50%, -50%);
  }
`;

export const Label = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ theme }) =>
  theme &&
  css`
  
    background-color: ${theme.colors.primary};
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.black};
    color: white;

    min-height: 36px;

  `}
`;