import React from 'react';
import { CarouselProvider, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Container, SlideWrapper, Background, Slogan, SloganContainer, Text, Heading } from './Hero.styled';
import { Wrapper, PrimaryText } from '../../components';

import image from '../../assets/images/hero.jpg';
import { Button } from '../../components/Button/Button.styled';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={1}
        visibleSlides={1}
        dragEnabled={false}
        touchEnabled={false}
      >
        <Slider>
          <SlideWrapper index={0}>
            <Background backgroundImage={image}>
              <SloganContainer>
                <Wrapper withPadding>
                  <Slogan>
                    <Heading type='h2'>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.4 46.64"><title>Guarantee icon</title><path d="M39.59,24.87c-3.25,10.21-8.83,17.18-17.05,21.32l-.9.45-.9-.45C5.58,38.56,1.93,22.33.35,7.46L0,4.11l3.11,1.3c4.31,1.79,10,.26,17.42-4.67L21.64,0l1.11.74a36.32,36.32,0,0,0,9.19,4.67,2,2,0,1,1-1.17,3.82,38,38,0,0,1-9.13-4.44C15,9,9.43,10.7,4.67,10c1.64,13.13,5.19,25.73,17,32.17C28.45,38.43,33,32.53,35.78,23.65a2,2,0,0,1,3.81,1.22Zm2-15.5a3,3,0,0,0-4.24,0L21.23,25l-6.75-6.69a3,3,0,1,0-4.22,4.26L21.2,33.38,41.5,13.61A3,3,0,0,0,41.55,9.37Z" /></svg> */}
                      { t('heroHeading') }
                    </Heading>
                    <Text>{ t('heroDescription') }</Text>
                    <Button onClick={() => history.push('/register')} primary>{ t('heroButtonText') }</Button>
                  </Slogan>
                </Wrapper>
              </SloganContainer>
            </Background>
          </SlideWrapper>
        </Slider>
      </CarouselProvider>
    </Container>
  );
}