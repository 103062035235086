import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* overflow-x: hidden; */
`;
export const Menu = styled.ul`
  width: 100%;
  margin: 0;
`;
export const MenuItem = styled.li`
  margin-top: ${({ theme }) => theme.gutter / 2}px;
  &:first-child {
    margin-top: 0;
  }
`;