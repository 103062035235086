import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import { PrimaryText } from '../';
import { Arrow, Container, Header, Heading, Content, SerialNumber } from './Accordion.styled';

export default ({ heading, serial, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Header onClick={() => setOpen(!open)}>
        <Heading>{ heading }</Heading>
        { serial && <SerialNumber>{ serial }</SerialNumber> }
        <Arrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.48 14.14" active={open}>
          <title>accordion arrow</title>
          <polygon points="1.41 14.14 8.48 7.07 1.41 0 0 1.42 5.66 7.07 0 12.73 1.41 14.14"/>
        </Arrow>
      </Header>
      <Collapse isOpened={open}>
        <Content>
          { children }
        </Content>
      </Collapse>
    </Container>
  );
};
