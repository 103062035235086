import * as utils from '../utils';
import { fetchEntity } from '../../services/content.service';

const getDefaults = () => ({});

class Backend {
  constructor(services, options = {}) {
    this.init(services, options);

    this.type = 'backend';
  }

  init(services, options = {}) {
    this.services = services;
    this.options = utils.defaults(options, this.options || {}, getDefaults());
  }

  read(language, namespace, callback) {
    const loadPath = this.options.loadPath;
    const versionPath = this.options.versionPath;

    const versionUrl = this.services.interpolator.interpolate(versionPath, { slug: language });
    const url = this.services.interpolator.interpolate(loadPath, { slug: language });

    this.loadTranslations(url, versionUrl, language, callback);
  }

  async loadTranslations(url, versionUrl, language, callback) {
    try {
      const newVersion = await this.checkNewVersion(versionUrl, language);
      let translations = {};
      if (newVersion === null) {
        translations.results = this.getTranslationsFromStorage(language);
      }

      if (newVersion !== null || !translations.results) {
        translations = await fetchEntity(url);
        this.saveNewVersion(translations.results, newVersion, language);
      }

      callback(null, translations.results);
    } catch (e) {
      callback(e, null);
    }
  }

  saveNewVersion(data, version, language) {
    localStorage.setItem(`dakea-net-${language}`, JSON.stringify(data));
    localStorage.setItem(`dakea-net-${language}-version`, version);
  }

  getTranslationsFromStorage(language) {
    const translationsString = localStorage.getItem(`dakea-net-${language}`);
    if (translationsString) {
      return JSON.parse(translationsString);
    }

    return null;
  }

  async checkNewVersion(versionUrl, language) {
    const response = await fetchEntity(versionUrl);
    const version = response.results.language.version;
    const storageVersion = localStorage.getItem(`dakea-net-${language}-version`)

    return version !== storageVersion ? version : null;
  }
}

Backend.type = 'backend';


export default Backend;