export const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const AUTH_PATH = `${BASE_PATH}auth`;
export const AUTH_SIGN_IN_PATH = `${AUTH_PATH}/sign-in/`;
export const AUTH_SIGN_UP_PATH = `${AUTH_PATH}/sign-up/`;
export const AUTH_FORGOT_PASSWORD_PATH = `${AUTH_PATH}/password-lost/`;
export const AUTH_RESET_PASSWORD_PATH = `${AUTH_PATH}/password-reset/`;
export const AUTH_REFRESH_TOKEN_PATH = `${AUTH_PATH}/refresh-token/`;
export const CONTENT_PATH = `${BASE_PATH}content/?slug={{slug}}`;
export const CONTENT_VERSION_PATH = `${BASE_PATH}content/version/?slug={{slug}}`;
export const CONTENT_LANGS_LIST = `${BASE_PATH}content/languages/`;
export const CONTENT_PRODUCTS_PATH = `${BASE_PATH}content/assets/?slug={{slug}}&pack={{pack}}`;
export const USER_PATH = `${BASE_PATH}user/`;
export const USER_PROFILE_UPDATE_PATH = `${USER_PATH}update/profile/`;
export const USER_PASSWORD_UPDATE_PATH = `${USER_PATH}update/password/`;
export const INSTALLATION_PATH = `${BASE_PATH}installation/`;
export const INSTALLATION_CREATE_PATH = `${INSTALLATION_PATH}create/`;
export const INSTALLATION_CERTIFICATE_PATH = `${BASE_PATH}certificate/?cert_token={{cert_token}}&lang={{lang}}`;
export const CONTACT_PATH = `${BASE_PATH}utils/contact/`;

export const interpolate = (path, config) => {
  return path.replace(/{{([^{}]*)}}/g,
    function (a, b) {
      var r = config[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    }
  );
}

export const getInstallationCertificatePath = (params) => {
  return interpolate(INSTALLATION_CERTIFICATE_PATH, params);
}