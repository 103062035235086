import styled, { css } from 'styled-components';

export const Heading = styled.h2`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 3}px;
    font-weight: ${theme.fontWeight.black};
    line-height: ${theme.fontSize.base * 2.6}px;

    text-transform: uppercase;
    text-align: center;

    margin-bottom: ${theme.gutter * .75}px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base}px;
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.fontSize.base * 1.3}px;

    text-align: center;
  `}
`;

export const SliderWrapper = styled.div`
  max-width: 1105px;
  margin: 0 auto;
  text-align: center;
  
  ${({ theme }) =>
  theme &&
  css`
    padding-top: ${theme.gutter / 2}px;
  `}

  .carousel {
    display: flex;
    align-items: center;
  }
`;
