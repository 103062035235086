import React from 'react';
import colors from '../../theme/colors';

export default function HamburgerButton({ width = 16, height = 12, open = false, strokeWidth = 2, animationDuration = 0.4, color = '#fff', onClick }) {
  const halfHeight = Math.round(height / 2);
  const halfStrokeWidth = Math.round(strokeWidth / 2);
  const isOpen = open || false;

  const getTransformValue = (isOpen, basePos, rotate, halfHeight) => {
    return `translate3d(0,${isOpen ? halfHeight + 'px' : basePos + 'px'},0) rotate(${isOpen ? `${rotate}deg` : '0'})`;
  };

  const wrapper = {
    width: 48,
    height: 48,
    padding: 0,
    background: colors.primary,
    position: 'relative',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const containerStyle = {
    width,
    height,
    position: 'relative'
  };

  const baseStyle = {
    display: 'block',
    height: `${strokeWidth}px`,
    width: '100%',
    background: color || '#000',
    transitionTimingFunction: 'ease',
    transitionDuration: `${animationDuration}s`,
    transformOrigin: 'center',
    position: 'absolute',
    marginTop: -halfStrokeWidth
  };

  const firstLineStyle = {
    transform: getTransformValue(isOpen, 0, '45', halfHeight),
  };

  const secondLineStyle = {
    transitionTimingFunction: 'ease-out',
    transitionDuration: `${animationDuration / 4}s`,
    opacity: isOpen ? 0 : 1,
    top: halfHeight
  };

  const thirdLineStyle = {
    transform: getTransformValue(isOpen, height, '-45', halfHeight)
  };

  return (
    <div style={wrapper} onClick={onClick}>
      <div style={containerStyle}>
        <span style={Object.assign({}, baseStyle, firstLineStyle)} />
        <span style={Object.assign({}, baseStyle, secondLineStyle)} />
        <span style={Object.assign({}, baseStyle, thirdLineStyle)} />
      </div>
    </div>
  );
}