import HttpStatus from 'http-status-codes';

import { interpolate, CONTENT_PRODUCTS_PATH } from './endpoints';

const applyHeaders = () => ({
  'Content-Type': 'application/json'
});

export const fetchEntity = async (url) => {
  const response = await fetch(url, {
    headers: { ...applyHeaders() }
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    return content;
  } else {
    const error = await response.json();
    throw new Error(`[ERROR: content.service]: ${JSON.stringify(error)}`);
  }
}

export const fetchProducts = () => new Promise(async (resolve, reject) => {
  const response = await fetch(
    interpolate(CONTENT_PRODUCTS_PATH, { slug: localStorage.getItem('i18nextLng'), pack: 'products' }
  ), {
    method: 'GET',
    headers: { ...applyHeaders() }
  });

  if (response.status === HttpStatus.OK) {
    const content = await response.json();
    resolve(content);
  } else {
    const error = await response.json();
    reject(error);
  }
})
