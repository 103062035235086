import styled, { css } from 'styled-components';

export const Forgot = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    font-weight: ${theme.fontWeight.medium};
    text-align: center;
    
    margin-top: ${theme.gutter * 1.18}px;
  `}
`;

export const Professional = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    background-color: ${theme.colors.gray100};

    margin-left: -${theme.gutter * .75}px;
    margin-right: -${theme.gutter * .75}px;
    margin-top: ${theme.gutter * 1.5}px;

    padding: ${theme.gutter * .6}px;

    text-align: center;

    @media (min-width: ${theme.breakpoints.sm}) {
      margin-left: -${theme.gutter * 1.25}px;
      margin-right: -${theme.gutter * 1.25}px;
    }

    p {
      font-size: ${theme.fontSize.base * 1.25}px;
      font-weight: ${theme.fontWeight.black};
      line-break: ${theme.fontSize.base * 1.25}px;
      
      margin-bottom: ${theme.gutter / 3}px;
    }
  `}
`;

export const FormError = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f5f7;
  margin-bottom: ${({ theme }) => theme.gutter / 2}px;
  padding: ${({ theme }) => theme.gutter / 2.5}px;

  p {
    ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.fontSize.base * .92}px;
    `}
  }
`;

export const ErrorIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #fde8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 12px;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: #e02424;
  }
`;