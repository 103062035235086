import styled, { css } from 'styled-components';
import { HeadingTemplate, Button } from '../../components';

export const Background = styled.div`
  background-color: white;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 100px);
  height:auto;

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      min-height: 60vh;
        height:auto;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      min-height: calc(100vh - 100px);
        height:auto;
    }
  `}
`;

export const Left = styled.aside`
  width: 100%;
  height: calc(100vh - 100px);

  background: left/cover no-repeat;
  
  ${({ backgroundImage }) =>
  backgroundImage &&
  css`
    background-image: url(${backgroundImage});
  `}

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      height: auto;
    }
    @media (min-width: ${theme.breakpoints.md}) {
      height: auto;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      p {
        padding-right: ${theme.gutter * 3.5}px;
      }
    }
  `}
`;
export const Right = styled.aside`
  width: 100%;
  height: auto

  background: center/cover no-repeat;

  ${({ backgroundImage }) =>
  backgroundImage &&
  css`
    background-image: url(${backgroundImage});
  `}

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height:auto;
      text-align: right;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      height: auto;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      div {
        display: flex;
        align-items: flex-end;
      }
      p {
        padding-left: ${theme.gutter * 3.5}px;
      }
    }
  `}
`;

export const Content= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  height: auto;

  padding: ${({ theme }) => theme.gutter / 2}px;

  background-color: rgba(0, 0, 0, .8);
  color: white;

  ${({ primary, theme }) =>
  primary &&
  theme &&
  css`
    background-color: rgba(${theme.colors.primaryRGB}, .8);
    color: black;
  `}

  ${({ theme }) =>
  theme &&
  css`
    @media (min-width: ${theme.breakpoints.md}) {
      width: auto;
      height: auto;
      padding: ${theme.gutter * 2}px;
      justify-content: flex-start;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      max-width: 700px;
      height:auto;

      button, a {
        max-width: 260px;
        width: 100%;
        flex-shrink: 0;
      }
    } 
  `}
`;
export const Heading = styled(HeadingTemplate)`
  ${({ theme }) =>
  theme &&
  css`
    margin-bottom: ${theme.gutter / 2}px;
  `}
`;
export const Text = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.fontSize.base * 1.2}px;
    font-weight: ${theme.fontWeight.black};
    line-height: ${theme.fontSize.base * 1.4}px;
    text-transform: uppercase;

    margin-bottom: ${theme.gutter / 2}px;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSize.base * 1.5}px;
      line-height: ${theme.fontSize.base * 1.6}px;

      margin-bottom: ${theme.gutter}px;
    }
  `}
`;